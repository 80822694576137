import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Subscription, combineLatest, iif, of } from 'rxjs';
import {
  EntityQuery,
  Predicate
} from 'breeze-client';

import { notEmpty } from '../common/util/not-empty';

import { AuthService } from '../services/auth.service';
import { WorkspaceManagerService } from '../services/workspace-manager.service';
import { BaseEntityService } from '../services/base-entity.service';
import { Workspace } from '@common/types/models/workspace.interface';
import { Entity } from '@common/types';
import { WorkspaceFilterService } from '@services/workspace-filter.service';
import { RoutingService } from '../routing/routing.service';
import {
  IconContent,
  alertOctagon,
  alertOctagonFilled,
  animals,
  animalsFilled,
  bell,
  bellFilled,
  births,
  birthsFilled,
  book,
  bookFilled,
  businessCenter,
  businessCenterFilled,
  calendar,
  calendarFilled,
  census,
  censusFilled,
  chart,
  clinical,
  clinicalFilled,
  cloudOut,
  cloudOutFilled,
  cohorts,
  cohortsFilled,
  flag,
  flagFilled,
  gear,
  gearFilled,
  genotype,
  housing,
  housingFilled,
  key,
  lines,
  listView,
  locations,
  locationsFilled,
  magnifier,
  mating,
  mechanism,
  mechanismFilled,
  orders,
  ordersFilled,
  orderDown,
  pin,
  pinFilled,
  plate,
  plateFilled,
  reports,
  reportsFilled,
  tasks,
  tasksFilled,
  tube,
  tubeFilled,
  user,
  userFilled,
  users,
  usersFilled,
  vocabularies,
  vocabulariesFilled,
  wifi,
  wifiFilled,
  workflow
} from '@common/icons';
import {WebApiService} from "@services/web-api.service";
import { IRoleFacet, RoleService } from '@services/role.service';
import {
    FACET_STORE_TOKEN,
    FacetDimensions,
    FacetStore,
    MODERN_FACETS_STORE_TOKEN,
    ModernFacetsStore,
    WORKSPACE_STORE_TOKEN,
    WorkspaceStore,
} from '../mf-store';
import { filter, map, switchMap } from 'rxjs/operators';
import { ModernAppService } from '@common/modern-app.service';

@Injectable()
export class WorkspaceService extends BaseEntityService {
  readonly facetIcons = new Map<string, { name: IconContent, header: IconContent }>(Object.entries({
    'Animals': { name: animals, header: animalsFilled },
    'Audit': { name: flag, header: flagFilled },
    'Audit (New)': { name: flag, header: flagFilled },
    'Births': { name: births, header: birthsFilled },
    'Characteristics': { name: mechanism, header: mechanismFilled },
    'Clinical': { name: clinical, header: clinicalFilled },
    'Constructs': { name: plate, header: plateFilled },
    'Enumerations': { name: listView, header: listView },
    'Genotypes': { name: genotype, header: genotype },
    'Housing': { name: housing, header: housingFilled },
    'Jobs': { name: pin, header: pinFilled },
    'Lines': { name: lines, header: lines },
    'Locations': { name: locations, header: locationsFilled },
    'Matings': { name: mating, header: mating },
    'Monitoring': { name: bell, header: bellFilled },
    'Plates': { name: plate, header: plateFilled },
    'Protocols': { name: orderDown, header: orderDown },
    'Protocols (New)': { name: orderDown, header: orderDown },
    'Reporting': { name: reports, header: reportsFilled },
    'Resources': { name: users, header: usersFilled },
    'Roles': { name: key, header: key },
    'Roles (New)': { name: key, header: key },
    'Samples': { name: tube, header: tubeFilled },
    'Schedule': { name: calendar, header: calendarFilled },
    'Search': { name: magnifier, header: magnifier },
    'Settings': { name: gear, header: gearFilled },
    'Studies': { name: book, header: bookFilled },
    'Tasks': { name: tasks, header: tasksFilled },
    'Users': { name: user, header: userFilled },
    'Vocabularies': { name: vocabularies, header: vocabulariesFilled },
    'Workflows': { name: workflow, header: workflow },
    'Charts': { name: chart, header: chart },
    'Import': { name: cloudOut, header: cloudOutFilled },
    'Cohorts': { name: cohorts, header: cohortsFilled },
    'IoT Alerts': { name: alertOctagon, header: alertOctagonFilled },
    'Devices': { name: wifi, header: wifiFilled },
    'IoT Plots': { name: chart, header: chart },
    'Institutions': { name: businessCenter, header: businessCenterFilled },
    'Jobs Pharma': { name: pin, header: pinFilled },
    'Orders': { name: orders, header: ordersFilled },
    'Census': { name: census, header: censusFilled },
    'CageCards': { name: reports, header: reportsFilled },
  }));

  readonly WORKSPACE_NAME_DEFAULT = 'Untitled';
  readonly WORKSPACE_NAME_LENGTH_MAX = 35;

  facets$ = this.facetStore.getValue$();
  manuallyChanged$ = this.facets$.pipe(map(facets => facets.some(facet => Boolean(facet.expanded))));

  private currentWorkspaceSource = new Subject<Entity<Workspace>>();
  currentWorkspace$ = this.currentWorkspaceSource.asObservable();

  private workspacesSource = new BehaviorSubject<Entity<Workspace>[]>(null);
  workspaces$ = this.workspacesSource.asObservable();

  private workspaceConnectivity = new Subject<any>();
  workspaceConnectivity$ = this.workspaceConnectivity.asObservable();

  currentWorkspace: Entity<Workspace> | null;
  workspaces: Entity<Workspace>[] | null = null;
  isCustomizeWorkspaceActive$ = this.workspaceStore.getValue$().pipe(
    map(state => state.active),
  );

  get isCustomizeWorkspaceActive(): boolean {
    return this.workspaceStore.getValue().active;
  }
  set isCustomizeWorkspaceActive(value: boolean) {
    this.workspaceStore.events.setActiveEvent(value);
  }

  private modernInitSub = new Subscription();

  constructor(
    private workspaceManager: WorkspaceManagerService,
    private authService: AuthService,
    private webApiService: WebApiService,
    private workspaceFilterService: WorkspaceFilterService,
    private routingService: RoutingService,
    private roleService: RoleService,
    @Inject(WORKSPACE_STORE_TOKEN)
    private workspaceStore: WorkspaceStore,
    @Inject(FACET_STORE_TOKEN)
    private facetStore: FacetStore,
    @Inject(MODERN_FACETS_STORE_TOKEN)
    private modernFacetsStore: ModernFacetsStore,
    private modernApp: ModernAppService,
  ) {
    super();
  }

  getCurrentWorkspace(): any {
    return this.currentWorkspace;
  }

  getFacetById$(facetId: string) {
    return this.facets$.pipe(map(facets => facets.find(facet => facet.id === facetId)));
  }

  async fetchWorkspaces(): Promise<Entity<Workspace>[]> {
    const p1 = new Predicate('UserName', '==', this.authService.getCurrentUserName());
    const p2 = new Predicate('IsTemplate', '==', true);

    const query = EntityQuery.from('Workspaces')
      .expand('WorkspaceDetail')
      .where(p1.or(p1.and(p2)))
      .orderBy('WorkspaceName');

    await this.workspaceManager.init();
    const workspaces = await this.workspaceManager.returnQueryResults(query);
    this.updateWorkspaces(workspaces);
    if (notEmpty(workspaces)) {
      // set custom properties used by gridster
      let index = 0;
      for (const workspace of workspaces) {
        if (workspace.id === undefined) {
          workspace.id = index;
        }
        index++;
      }
      if (!this.currentWorkspace) {
        this.setCurrentWorkspace(workspaces[0]);
      }
    }

    return workspaces;
  }

  updateWorkspaces(workspaces: Entity<Workspace>[]): void {
    this.workspacesSource.next(workspaces);
    this.workspaces = workspaces;
  }

  openClinicalFacet(animalKey: any) {
      this.workspaceConnectivity.next(animalKey);
  }

  setCurrentWorkspace(workspace: Entity<Workspace> | null) {
    this.currentWorkspace = workspace;
    this.currentWorkspaceSource.next(workspace);
    this.modernInitSub.unsubscribe();

    this.modernInitSub = this.modernApp.initStatus$
      .pipe(
        filter(status => status !== 'unknown'),
        switchMap(status => status === 'success'
          ? this.modernFacetsStore.getValue$().pipe(map(({ initialized }) => initialized))
          : of(true),
        ),
        filter(Boolean),
      )
      .subscribe(() => {
        const modernFacets = this.modernFacetsStore.getValue().facets;
        const modernFacetIds = new Set(modernFacets.map(facet => facet.componentId))
        this.facetStore.events.initEvent(workspace?.WorkspaceDetail.map((item) => ({
          componentId: item.FacetName,
          type: modernFacetIds.has(item.FacetName) ? 'modern' : 'old',
          facet: item,
        })) ?? []);
      })
  }

  getWorkspaceTemplates(): Promise<unknown> {
    const query = EntityQuery.from('Workspaces')
      .expand('WorkspaceDetail')
      .where('IsTemplate', '==', true)
      .orderBy('WorkspaceName');

    return this.workspaceManager.returnQueryResults(query);
  }

  createWorkspace(initialValues: any, index: number): any {
    const workspace = this.workspaceManager.createEntity('Workspace', initialValues);
    workspace.UserName = this.authService.getCurrentUserName();

    if (workspace.id === undefined) {
        workspace.id = index;
    }

    return workspace;
  }

  createWorkspaceFromTemplate(initialValues: any, index: number, wsTemplate: any): any {
    const workspace = this.createWorkspace(initialValues, index);
    
    // copy workspace details from the template workspace
    for (const detail of wsTemplate.WorkspaceDetail) {
      const newDetail = this.createWorkspaceDetail({
        C_Workspace_key: this.currentWorkspace.C_Workspace_key,
        FacetName: detail.FacetName,
        FacetDisplayName: detail.FacetDisplayName,
        Column: detail.Column,
        Row: detail.Row,
        SizeX: detail.SizeX,
        SizeY: detail.SizeY,
        GridFilter: detail.GridFilter,
        GridState: detail.GridState
      });
      workspace.WorkspaceDetail.push(newDetail);
    }

    return workspace;
  }

  createWorkspaceDetail(initialValues: any): any {
    const facet = this.workspaceManager.createEntity('WorkspaceDetail', initialValues);

    if (facet.templateURL === undefined) {
        facet.templateURL = "app/facets/" + facet.FacetName.toLowerCase() + ".html";
    }

    return facet;
  }

  navigateToWorkspace(workspace: Entity<Workspace>): void {
    // discard any facet configuration changes
    // since those only persist via un-lock mode
    this.workspaceManager.cancel();
    this.isCustomizeWorkspaceActive = false;

    if (this.workspaceFilterService.getFilterKind() !== null) {
      // turn off workspace filter before switching
      this.workspaceFilterService.clearWorkspaceFilter();
    }

    this.setCurrentWorkspace(workspace);
    this.routingService.navigateToWorkspacesWithID(workspace);
  }

  getWorkspaceDetail(workspaceDetailKey: number): any {
    const predicate = new Predicate('C_WorkspaceDetail_key', '==', workspaceDetailKey);
    const query = EntityQuery.from('WorkspaceDetails')
        .where(predicate)
        .orderBy('C_WorkspaceDetail_key');
    const workspaceDetails = this.workspaceManager
      .getManager()
      .executeQueryLocally(query);

    return workspaceDetails?.[0] ?? null;
  }

  deleteWorkspace(workspace: any): void {
    while (workspace.WorkspaceDetail.length > 0) {
      const workspaceDetail = workspace.WorkspaceDetail[0];
      this.workspaceManager.deleteEntity(workspaceDetail);
    }

    this.workspaceManager.deleteEntity(workspace);
  }

  async deleteWorkspaceDetail(facetId: string) {
    this.facetStore.events.closeEvent({ id: facetId });
  }
    
  validateWorkspaceName(workspace: any, allWorkspaces: any[]): void {
    this.resolveBlankOrLongWorkspaceName(workspace);
    this.resolveDuplicateWorkspaceName(workspace, allWorkspaces);
  }

  private resolveBlankOrLongWorkspaceName(workspace: any): void {
    const workspaceName = workspace.WorkspaceName
      ?.substring(0, this.WORKSPACE_NAME_LENGTH_MAX) ?? this.WORKSPACE_NAME_DEFAULT;

    workspace.WorkspaceName = workspaceName;
  }

  private resolveDuplicateWorkspaceName(workspace: any, allWorkspaces: any[]): void {
    let workspaceName = workspace.WorkspaceName;

    // Check uniqueness only if there are other workspaces.
    if (allWorkspaces && allWorkspaces.length > 0) {
      // Get array of all names excluding this workspace.
      const otherNames: string[] = this.getOtherWorkspaceNames(workspace, allWorkspaces);

      // If current name is not unique, add incrementing suffixes until it is.
      if (otherNames.indexOf(workspaceName) !== -1) {
        let ix = 1;
        while (otherNames.indexOf(
          this.getWorkspaceNameSuffixed(workspaceName, ix)
        ) !== -1) {
          ix++;
        }

        // Uniqueness achieved with the last suffix.
        workspaceName = this.getWorkspaceNameSuffixed(workspaceName, ix);
      }
    }

    workspace.WorkspaceName = workspaceName;
  }

  /**
   * Constructs array of workspace names excluding the current workspace.
   */
  private getOtherWorkspaceNames(workspace: any, allWorkspaces: any[]): string[] {
    return allWorkspaces
      .filter(item => item.C_Workspace_key !== workspace.C_Workspace_key)
      .map(item => item.WorkspaceName);
  }

  /**
   * Adds numeric suffix to workspace name.
   */
  private getWorkspaceNameSuffixed(workspaceName: string, index: number): string {
    return `${workspaceName} (${index})`;
  }

  async initializeFacets(userRole: string): Promise<any> {
    const workspace = this.currentWorkspace;

    if (!workspace || !workspace.WorkspaceDetail) {
      this.routingService.navigateToDashboard();
      return;
    }
    try {
      const promises = workspace.WorkspaceDetail.map(
        detail => this.roleService.getFacetPrivilegeByRole(detail.FacetName, userRole)
          .then(facetRole => {
            const extended = detail as typeof detail & { Privilege: string };
            extended.Privilege = this.getPrivilege(facetRole.HasWriteAccess, facetRole.HasReadAccess);
          }),
      );
      await Promise.all(promises);
    } catch (error) {
      throw error;
    }
  }

  private getPrivilege(hasWriteAccess: boolean, hasReadAccess: boolean): string {
      if (hasWriteAccess) {
          return 'ReadWrite';
      }
      if (hasReadAccess) {
          return 'ReadOnly';
      }
      return 'None';
  }

  hasSavedFacets(): boolean {
    const savedFacets = this.currentWorkspace.WorkspaceDetail.filter((item: any) => {
      return item.C_WorkspaceDetail_key > 0;
    });
    return savedFacets.length > 0;
  }

  /**
   * Same as autoSizeFacets, but does not check if facets were changed or saved
   */
  forceAutoSizeFacets() {
    this.facetStore.events.autoSizeEvent(true);
  }

  autoSizeFacets() {
    this.facetStore.events.autoSizeEvent(false);
  }

  collapseAllFacets() {
    this.facetStore.events.collapseAllEvent();
  }

  collapseFacet(id: string) {
    this.facetStore.events.collapseEvent({ id });
  }

  expandFacet(id: string) {
    this.facetStore.events.expandEvent({ id });
  }

  addFacet(roleFacet: IRoleFacet) {
    const facetSelection = {
      facetName: roleFacet.Facet.FacetName,
      facetDisplayName: roleFacet.Facet.FacetDisplayName,
      hasReadAccess: roleFacet.HasReadAccess,
      hasWriteAccess: roleFacet.HasWriteAccess
    };
    const facet = this.createWorkspaceDetail({
      C_Workspace_key: this.currentWorkspace.C_Workspace_key,
      FacetName: roleFacet.Facet.FacetName,
      FacetDisplayName: roleFacet.Facet.FacetDisplayName,
      Column: null,
      Row: null,
      SizeX: null,
      SizeY: null
    });

    facet.Privilege = this.getPrivilege(facetSelection.hasWriteAccess, facetSelection.hasReadAccess);
    const modernFacets = this.modernFacetsStore.getValue();

    const modernFacetIds = new Set(modernFacets.facets.map(facet => facet.componentId));

    this.facetStore.events.addEvent({
      componentId: facet.FacetName,
      type: modernFacetIds.has(facet.FacetName) ? 'modern' : 'old',
      facet,
    });
  }

  addModernFacet(facet: ModernFacet) {
    this.facetStore.events.addEvent({
      componentId: facet.componentId,
      type: 'modern',
      facet: facet,
    });
  }

  manuallyFacetChanged(id: string) {
    this.facetStore.events.changeEvent({ id, facet: { manually: true } });
  }

  changeDimension(id: string, key: keyof FacetDimensions, value: number) {
    this.facetStore.events.changeEvent({ id, facet: { [key]: value } });
  }

  // TODO: check what is it ? (unknown logic)
  /**
   * Save just the BulkEditConfiguration value for the facet
   *
   * @param facet (aka WorkspaceDetail) entity
   */
  saveBulkEditConfiguration(facet: any): Promise<any> {
    return this.workspaceManager.saveChangesToProperty(facet, 'BulkEditConfiguration');
  }

  /**
   * Save just the BulkDataConfiguration value for the facet
   *
   * @param facet (aka WorkspaceDetail) entity
   */
  saveBulkDataConfiguration(facet: any): Promise<any> {
    return this.workspaceManager.saveChangesToProperty(facet, 'BulkDataConfiguration');
  }

  /**
   * Save just the TaskGridConfiguration value for the facet
   *
   * @param facet (aka WorkspaceDetail) entity
   */
  saveTaskGridConfiguration(facet: any): Promise<any> {
    return this.workspaceManager.saveChangesToProperty(facet, 'TaskGridConfiguration');
  }

  /**
   * Mark Workspace facet as favourite
   *
   * @param workspaceKey number
   * @param favouriteFlag boolean
   */
  markWorkspaceAsFavourite(workspaceKey: number, favouriteFlag: boolean): Promise<any> {
    return this.webApiService.postApi(`api/workspace/mark-as-favourite/${workspaceKey}`, {
      favouriteFlag
    });
  }
}
