import { EventCallable } from 'effector';
import { InjectionToken, NgZone, Provider } from "@angular/core";
import { partial } from "lodash";
import { mappedStore$, mappedStore, StoreWrapper } from "../helpers";
import { $modernFacets, initEvent, ModernFacetsStore as StoreData } from "./modern-facets";
import { Store } from '../types';

export type ModernFacetsStore = Store<StoreData, {
  initEvent: EventCallable<ModernFacet[]>;
}>;

const Api: ModernFacetsStore = {
  __store: $modernFacets,
  events: {
    initEvent,
  },
  getValue$: partial(mappedStore$, $modernFacets),
  getValue: partial(mappedStore, $modernFacets),
};

export const MODERN_FACETS_STORE_TOKEN = new InjectionToken<ModernFacetsStore>('MODERN_FACETS_STORE_TOKEN');
export const MODERN_FACETS_STORE_PROVIDER: Provider = {
  provide: MODERN_FACETS_STORE_TOKEN,
  useFactory: (ngZone: NgZone) => {
    const wrapper = new StoreWrapper(ngZone);
    wrapper.setApi(Api as Store);
    return wrapper;
  },
  deps: [NgZone],
};

window.mfStore ??= {};
window.mfStore.modernFacetsStore = Api;