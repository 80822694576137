import { DataContextService } from './../services/data-context.service';
import {
    Component,
    Input,
    OnInit
} from '@angular/core';

import { Recipient, RecipientType } from './models';
import * as escapeStringRegexp from 'escape-string-regexp';
import { RoleService } from '../services/role.service';
import { UserService } from '../user/user.service';

@Component({
    selector: 'recipient-select',
    templateUrl: './recipient-select.component.html',
    styles: [`
        .table {
            margin-bottom: 2px;
        }
    `]
})
export class RecipientSelectComponent implements OnInit {
    @Input() recipients: Recipient[];
    @Input() id: string;
    @Input() required: boolean;

    readonly ROLE_TYPE = RecipientType.Role;
    readonly USER_TYPE = RecipientType.User;

    // state
    private _recipientsPromise: Promise<Recipient[]>;

    get isRequired(): boolean {
        return this.required && this.recipients.length === 0;
    }

    constructor(
        private roleService: RoleService,
        private userService: UserService,
        private dataContext: DataContextService
    ) {}

    ngOnInit() {
        if (!this.recipients) {
            this.recipients = [];
        }

        this.dataContext.init().then(() => {
            this.refreshRecipients();
        });
    }

    refreshRecipients() {
        this._recipientsPromise = Promise.all([
            this.getUserRecipients(),
            this.getRoleRecipients()
        ]).then((groups: Recipient[][]) => { 
            // concat all recipients to one array
            let recipients: any[] = [];
            for (const group of groups) {
                recipients = recipients.concat(group);
            }
            return recipients;
        });
    }

    getRoleRecipients(): Promise<Recipient[]> {
        return this.roleService.getRoles().then((roles: any[]) => {
            return roles.map((role) => {
                return {
                    recipientName: role.RoleName,
                    recipientKey: role.C_ClimbRole_key,
                    type: RecipientType.Role
                };
            });
        });
    }

    getUserRecipients(): Promise<Recipient[]> {
        return this.userService.getWorkgroupUsersList().then((users: any[]) => {
            return users.map((user) => {
                return {
                    recipientName: user.text,
                    recipientKey: user.value,
                    type: RecipientType.User
                };
            });
        });
    }

    selectRecipient(recipient: Recipient) {
        if (this.recipients.indexOf(recipient) < 0) {
            this.recipients.push(recipient);
        }
    }

    removeRecipient(recipient: Recipient) {
        const index = this.recipients.indexOf(recipient);
        if (index >= 0) {
            this.recipients.splice(index, 1);
        }
    }

    searchRecipients = (text: string): Promise<Recipient[]> => {
        if (!this._recipientsPromise) {
            this.refreshRecipients();
        }

        const maxResults = 200;
       
        return this._recipientsPromise.then((recipients) => {
            let results: Recipient[] = [];
            if (recipients) {
                results = recipients.filter((choice) => {
                    // return match if text is empty
                    return !text || this.matchTypeaheadChoice(text, choice);
                });
            }

            if (results.length > maxResults) {
                results = results.splice(0, maxResults);
            }

            return results;
        });
    }

    matchTypeaheadChoice(text: string, choice: Recipient): boolean {
        const display = this.recipientFormatter(choice);
        const escapedSearch = escapeStringRegexp(text);
        return new RegExp(escapedSearch, 'gi').test(display);
    }

    recipientFormatter = (item: Recipient) => {
        let value = '';
        switch (item.type) {
            case RecipientType.Role:
                value = "(All) " + item.recipientName;
                break;
            case RecipientType.User:
                value = item.recipientName;
                break;
        }
        return value;
    }
}
