import { groupBy } from '@lodash';
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ENV_CONFIG, EnvironmentConfig } from '@config';
import { AdminManagerService } from '../services/admin-manager.service';
import { AuthService } from '../services/auth.service';
import { BaseEntityService } from '../services/base-entity.service';
import { Message } from '@common/types/admincore.graphql.type';
import type { InboxMessage } from './models';
import { MessageMap, MessageMapDraft } from './models';

type GQLResponse<T> = {
    data: T;
};

type Messages = {
    messages: {
        nodes: Message[];
    };
};

type MessageCount = {
    messages: {
        totalCount: number;
    };
};

type MessageCountResponse = GQLResponse<MessageCount>;

const GQL_MESSAGE_COUNT_QUERY = `
  query Messages($toUserId: String, $showDismissed: Boolean) {
    messages(
      where: {
        isDismissed: { eq: $showDismissed }
        toUserId: { eq: $toUserId }
      }
    ) {
      totalCount
    }
  }
`;

type MessagesResponse = GQLResponse<Messages>;

const GQL_MESSAGES_QUERY = `
  query Messages($where: MessageFilterInput) {
    messages(where: $where) {
      nodes {
        id
        fromUserId
        fromUser {
          id
          firstName
          lastName
        }
        toUserId
        subject
        messageText
        isDismissed
        dateGenerated
      }
    }
  }
`;

type SentMessagesResponse = GQLResponse<Messages>;

const GQL_SENT_MESSAGES_QUERY = `
  query Messages($fromUserId: String, $searchText: String) {
    messages(
      where: {
        fromUserId: { eq: $fromUserId }
        messageText: { contains: $searchText }
      }
      order: { dateGenerated: DESC }
    ) {
      nodes {
        id
        fromUserId
        toUserId
        toUser {
          id
          firstName
          lastName
        }
        subject
        messageText
        isDismissed
        dateGenerated
      }
    }
  }
`;

type CreatedMessage = {
    bulkCreateMessages: {
        errors: {
            message: string;
            code: string;
        }[],
        message: Message[]
    }
};

type BulkCreatedMessagesResponse = GQLResponse<CreatedMessage>;

const GQL_BULK_CREATE_MESSAGES_MUTATION = `
  mutation BulkCreateMessages($input: BulkCreateMessagesInput!) {
    bulkCreateMessages(input: $input) {
      message {
        id
        fromUserId
        toUserId
        subject
        messageText
      }
      errors {
        ...on BaseError {
          message
          code
        }
        ...on ValidationError {
          message
          code
          errors {
            property
            message
          }
        }
      }
    }
  }
`;

type UpdatedMessage = {
    bulkUpdateMessages: {
        errors: {
            message: string;
            code: string;
        }[],
        message: Message[]
    }
};

type BulkUpdatedMessagesResponse = GQLResponse<UpdatedMessage>;

const GQL_BULK_UPDATE_MESSAGES_MUTATION = `
  mutation BulkUpdateMessages($input: BulkUpdateMessagesInput!) {
    bulkUpdateMessages(input: $input) {
      message {
        id
        isDismissed
      }
      errors {
        ... on ApiError {
          code
          message
        }
        ... on ValidationError {
          code
          message
          errors {
            message
            property
          }
        }
      }
    }
  }
`;

type MessageMaps = {
    messageMaps: {
        nodes: MessageMap[];
    };
};

type MessageMapsResponse = GQLResponse<MessageMaps>;

const GQL_MESSAGE_MAPS_QUERY = `
  query MessageMaps($messageIds: [Int!]) {
    messageMaps(where: { messageId: { in: $messageIds } }) {
      nodes {
        genotypeId
        id
        lineId
        materialId
        job {
          jobId
        }
        messageId
        taskInstanceId
      }
    }
  }
`;

type CreateMessageMaps = {
    bulkCreateMessageMaps: {
        errors: {
            message: string;
            code: string;
        }[],
        messageMap: MessageMap[]
    }
};

type BulkCreateMessageMapsResponse = GQLResponse<CreateMessageMaps>;

const GQL_BULK_CREATE_MESSAGE_MAPS_MUTATION = `
  mutation BulkCreateMessageMaps($input: BulkCreateMessageMapsInput!) {
    bulkCreateMessageMaps(input: $input) {
      errors {
        ... on ApiError {
          code
          message
        }
        ... on ValidationError {
          code
          message
          errors {
            message
            property
          }
        }
      }
      messageMap {
        id
        messageId
      }
    }
  }
`;

@Injectable()
export class MessageService extends BaseEntityService {

    private newMessageCountSource = new Subject<number>();
    newMessageCount$ = this.newMessageCountSource.asObservable();


    constructor(
        private adminManager: AdminManagerService,
        private authService: AuthService,
        private httpClient: HttpClient,
        @Inject(ENV_CONFIG) private config: EnvironmentConfig,
    ) {
        super();
    }

    get adminUrl(): string {
        return this.config.graphQlAdminUrl + 'graphql';
    }

    get dataUrl(): string {
        return this.config.graphQlDataUrl + 'graphql';
    }

    getUserMessages(searchText: string, showDismissed: boolean): Promise<InboxMessage[]> {
        const where = {
            toUserId: { eq: this.authService.getCurrentUserId() },
            or: {
                subject: { contains: searchText },
                messageText: { contains: searchText },
            },
        };
        if (!showDismissed) {
            where['isDismissed'] = { eq: false };
        }

        return this.httpClient.post<MessagesResponse>(this.adminUrl, {
            operationName: 'Messages',
            query: GQL_MESSAGES_QUERY,
            variables: { where },
        }).pipe(
            map(({ data }) => data.messages.nodes),
            switchMap((messages) => this.getMessageMapData(messages).pipe(
                map((messageMaps) => {
                    const map = groupBy(messageMaps, 'messageId');
                    return messages.map((message) => ({
                        ...message,
                        messageMaps: map[message.id],
                    }));
                }),
            )),
            catchError((error) => this.adminManager.queryFailed(error)),
        ).toPromise();
    }

    getSentMessages(searchText: string): Promise<Message[]> {
        return this.httpClient.post<SentMessagesResponse>(this.adminUrl, {
            operationName: 'Messages',
            query: GQL_SENT_MESSAGES_QUERY,
            variables: {
                fromUserId: this.authService.getCurrentUserId(),
                searchText,
            },
        }).pipe(
            map(({ data }) => data.messages.nodes),
        ).toPromise();
    }

    async updateNewMessageCount(): Promise<void> {
        const count = await this.getNewMessageCount();
        this.newMessageCountSource.next(count);
    }

    getNewMessageCount(): Promise<number> {
        return this.httpClient.post<MessageCountResponse>(
            this.adminUrl,
            {
                operationName: 'Messages',
                query: GQL_MESSAGE_COUNT_QUERY,
                variables: {
                    toUserId: this.authService.getCurrentUserId(),
                    showDismissed: false,
                },
            },
            { headers: { 'x-cache-refresh': 'true' } },
        ).pipe(
            map(({ data }) => data.messages?.totalCount ?? 0),
        ).toPromise();
    }

    createMessage(toUserIds: string[], subject: string, messageText: string): Promise<Message[]> {
        const fromUserId = this.authService.getCurrentUserId();
        const messages = toUserIds.map((toUserId) => ({
            fromUserId,
            toUserId,
            subject,
            messageText,
        }));

        return this.httpClient.post<BulkCreatedMessagesResponse>(this.adminUrl, {
            operationName: 'BulkCreateMessages',
            query: GQL_BULK_CREATE_MESSAGES_MUTATION,
            variables: { input: { messages } },
        }).pipe(
            tap((response) => {
                const { errors } = response.data.bulkCreateMessages;
                if (errors && errors.length > 0) {
                    throw errors[0];
                }
            }),
            map(({ data }) => data.bulkCreateMessages.message ?? []),
        ).toPromise();
    }

    updateMessages(messages: Array<Pick<InboxMessage, 'id' | 'isDismissed'>>): Promise<Message[]> {
        return this.httpClient.post<BulkUpdatedMessagesResponse>(this.adminUrl, {
            operationName: 'BulkUpdateMessages',
            query: GQL_BULK_UPDATE_MESSAGES_MUTATION,
            variables: { input: { messages } },
        }).pipe(
            tap((response) => {
                const { errors } = response.data.bulkUpdateMessages;
                if (errors && errors.length > 0) {
                    throw errors[0];
                }
            }),
            map(({ data }) => data.bulkUpdateMessages.message ?? []),
        ).toPromise();
    }

    createMessageMap(messageMaps: MessageMapDraft[]): Promise<MessageMap[]> {
        return this.httpClient.post<BulkCreateMessageMapsResponse>(this.dataUrl, {
            operationName: 'BulkCreateMessageMaps',
            query: GQL_BULK_CREATE_MESSAGE_MAPS_MUTATION,
            variables: { input: { messageMaps } },
        }).pipe(
            tap((response) => {
                const { errors } = response.data.bulkCreateMessageMaps;
                if (errors && errors.length > 0) {
                    throw errors[0];
                }
            }),
            map(({ data }) => data.bulkCreateMessageMaps.messageMap ?? []),
        ).toPromise();
    }


    /**
     * Queries any MessageMap records for each Message in messages.
     * Attaches matched MessageMap objects to each Message via
     * message.messageMaps property
     * @param messages
     */
    getMessageMapData(messages: Message[]): Observable<MessageMap[]> {
        const messageIds = messages.map(({ id }) => id);

        return this.httpClient.post<MessageMapsResponse>(this.dataUrl, {
            operationName: 'MessageMaps',
            query: GQL_MESSAGE_MAPS_QUERY,
            variables: { messageIds },
        }).pipe(
            map(({ data }) => data.messageMaps.nodes),
        );
    }
}
