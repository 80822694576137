import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Inject, OnInit, Optional } from '@angular/core';
import { FeatureFlagService } from '@services/feature-flags.service';
import { WorkspaceManagerService } from '@services/workspace-manager.service';
import { UserNameService } from '../../../user/user-name.service';
import { UserService, Workgroup } from '../../../user/user.service';
import { TranslationService } from '@services/translation.service';
import { RoutingService } from '../../../routing/routing.service';
import { LoginService } from '../../../login/services/login.service';
import { LocalStorageService } from '@services/local-storage.service';
import { CurrentWorkgroupService } from '@services/current-workgroup.service';
import { CopyBufferService } from '@common/services/copy-buffer.service';
import { DataManagerService } from '@services/data-manager.service';
import { DataContextService } from '@services/data-context.service';
import { AdminManagerService } from '@services/admin-manager.service';
import { VocabularyService } from '../../../vocabularies/vocabulary.service';
import { expireCache } from '@common/util';
import { LocalStorageKey } from '@config';
import { Entity } from '@common/types';
import { DialogRef } from '@common/dialog/dialog-ref';
import { CLIMB_DIALOG_DATA } from '@common/dialog/dialog-data.token';
import { Observable } from 'rxjs';
import { TabStorageService } from 'src/app/multiply-tab/tab-storage.service';
import { MessageService } from 'src/app/messages/message.service';
import { WorkspaceService } from 'src/app/workspaces/workspace.service';

@Component({
    selector: 'climb-workgroup-switch-modal',
    templateUrl: './workgroup-switch-modal.component.html',
    styleUrls: ['./workgroup-switch-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkgroupSwitchModalComponent implements OnInit {
    @HostBinding('class.unsaved-changes') hasUnsavedChanges = false;
    switchingWorkgroup$: Observable<boolean>;
    selectedWorkgroupId: number;
    workgroups: Workgroup[];
    showCloseButton = false;
    message = 'You have unsaved changes that will be lost if you switch workgroups. Click Cancel and save your changes first.';

    get selectedWorkgroup() {
        return this.workgroups?.find((workgroup) => workgroup.workgroupId === this.selectedWorkgroupId);
    }

    constructor(
        private vocabularyService: VocabularyService,
        private adminManager: AdminManagerService,
        private dataContext: DataContextService,
        private dataManager: DataManagerService,
        private copyBufferService: CopyBufferService,
        private currentWorkgroupService: CurrentWorkgroupService,
        private localStorageService: LocalStorageService,
        private loginService: LoginService,
        private routingService: RoutingService,
        private translationService: TranslationService,
        private userService: UserService,
        private userNameService: UserNameService,
        private workspaceManager: WorkspaceManagerService,
        private featureFlagsService: FeatureFlagService,
        private dialogRef: DialogRef,
        private changeDetectorRef: ChangeDetectorRef,
        public tabStorage: TabStorageService,
        private messageService: MessageService,
        private workspaceService: WorkspaceService,
        @Optional() @Inject(CLIMB_DIALOG_DATA) data: { showCloseButton: boolean }
    ) {
        this.showCloseButton = data?.showCloseButton;
    }

    async ngOnInit() {
        await this.dataContext.init();
        await this.initialize();
    }

    async initialize() {
        this.hasUnsavedChanges = this.dataContext.getChangesCount() > 0;

        this.switchingWorkgroup$ = this.currentWorkgroupService.switchingWorkgroup$;
        this.workgroups = await this.userService.getUserWorkgroups();
        this.setCurrentWorkgroup();
        this.changeDetectorRef.markForCheck();
    }

    setCurrentWorkgroup() {
        if (this.workgroups) {
            const currentWorkgroupKey = Number(this.currentWorkgroupService.getCurrentWorkgroupKey());

            this.selectedWorkgroupId = this.workgroups
                .find((workgroup) => workgroup.workgroupId === currentWorkgroupKey)
                .workgroupId;
        }
    }

    close(): void {
        this.dialogRef.close();
    }

    async selectWorkgroup() {
        try {
            this.currentWorkgroupService.setSwitchingWorkgroup(true);

            this.currentWorkgroupService.setWorkgroupName(this.selectedWorkgroup.workgroupName);
            this.vocabularyService.resetCV();
    
            const user = await this.userService.getThisUser();
            user.C_CurrentWorkgroup_key = this.selectedWorkgroupId;
            await this.adminManager.saveEntity('User');
            this.clearWorkgroupContext();
            await this.currentWorkgroupService.switchWorkgroup(this.selectedWorkgroupId);
            await this.featureFlagsService.getFeatureFlags();
            await this.initializeNewWorkgroupContext();
            await this.processNewLogin();
            await this.finalizeSwitchWorkgroup();
            await this.messageService.updateNewMessageCount();
            this.tabStorage.workgroupChanged();
        } finally {
            this.currentWorkgroupService.setSwitchingWorkgroup(false);
        }
    }

    private async finalizeSwitchWorkgroup() {
        this.currentWorkgroupService.setSwitchingWorkgroup(false);
        this.close();
        this.dialogRef.close();
        await this.routingService.navigateToDashboard();
        const response = await this.currentWorkgroupService.getWorkgroupTimeZone();
        if (response?.data) {
            this.currentWorkgroupService.setWorkgroupTimeZone(response.data);
        }
    }

    clearWorkgroupContext(): void {
        this.localStorageService.remove(LocalStorageKey.CURRENT_POSITION_STORAGE_KEY);

        expireCache.remove();

        this.copyBufferService.clear();

        this.dataContext.cancel();
        this.workspaceManager.cancel();
        this.workspaceService.isCustomizeWorkspaceActive = false;
        this.dataContext.clear();
        this.workspaceManager.clear();
    }

    async initializeNewWorkgroupContext(): Promise<void> {
        await this.translationService.initialize();
        await this.userNameService.initialize();
        await this.dataManager.init();
    }

    async processNewLogin(): Promise<void> {
        await this.loginService.addUserLogin();
        const loginCount = await this.loginService.getLoginCount();
        if (loginCount === 1) {
            return this.loginService.onBoardUser();
        }
    }
}
