import { pickBy } from '@lodash';
import {
    Component,
    Input,
    OnInit,
    Output,
    EventEmitter
} from '@angular/core';

import { LoggingService } from '../services/logging.service';
import { MessageService } from './message.service';
import { RoleService } from '../services/role.service';

import { Message } from '../common/types/admincore.graphql.type';
import {
    MessageDraft,
    MessageMap,
    MessageMapDraft,
    RecipientType
} from './models';

import {
    notEmpty,
    randomId,
} from '../common/util';

@Component({
    selector: 'new-message',
    templateUrl: './new-message.component.html'
})
export class NewMessageComponent implements OnInit {
    @Input() defaultValues: any;
    @Output() save: EventEmitter<void> = new EventEmitter<void>();
    @Output() cancel: EventEmitter<void> = new EventEmitter<void>();

    message: MessageDraft;

    // state
    domIdAddition: string;
    sending: boolean;

    readonly COMPONENT_LOG_TAG = 'new-message';


    constructor(
        private loggingService: LoggingService,
        private messageService: MessageService,
        private roleService: RoleService,
    ) { }

    ngOnInit() {
        this.domIdAddition = randomId();
        this.sending = false;

        if (!this.defaultValues) {
            this.defaultValues = {};
        }

        this.message = {
            recipients: this.defaultValues.recipients || [],
            messageText: this.defaultValues.messageText || '',
            messageSubject: this.defaultValues.messageSubject || '',
            messageMaps: this.defaultValues.messageMaps || []
        };
    }


    // Form
    sendDisabled(): boolean {
        return this.sending || !this.formValid();
    }

    formValid(): boolean {
        return notEmpty(this.message.recipients) &&
            this.message.messageSubject.length > 0 &&
            this.message.messageText.length > 0;
    }

    cancelClicked(): void {
        this.cancel.emit();
    }

    sendClicked(): void {
        this.saveMessage().then(() => {
            this.save.emit();
        });
    }


    // Sending
    saveMessage(): Promise<any> {
        this.sending = true;

        return this.sendNewMessages().then((newMessages) => {
            if (this.message.messageMaps) {
                return this.saveMessageMaps(newMessages, this.message.messageMaps);
            }
            return Promise.resolve();
        }).then(() => {
            this.showSuccessMessage();
            this.sending = false;
        }).catch((error) => {
            console.error(error);
            this.sending = false;
        });
    }

    private showSuccessMessage() {
        this.loggingService.logSuccess(
            "Message sent",
            null,
            this.COMPONENT_LOG_TAG,
            true
        );
    }


    /**
     * Sends all new messages
     * (1 per recipient)
     */
    async sendNewMessages(): Promise<Message[]> {
        const toUserIds = [] as string[];

        for (const recipient of this.message.recipients) {
            // They selected a role from the list, gather all users in the role
            if (recipient.type === RecipientType.Role) {
                const users = await this.roleService.getUsersInRole(recipient.recipientKey);
                const userIds = users.map((user) => user.id);
                toUserIds.push(...userIds);
            } else {
                // Send to individual user
                toUserIds.push(`${recipient.recipientKey}`);
            }
        }

        return await this.messageService.createMessage(toUserIds, this.message.messageSubject, this.message.messageText);
    }

    async saveMessageMaps(messages: Message[], messageMaps: MessageMap[]): Promise<void> {
        const initialValues = messages.flatMap((message) => {
            return messageMaps.map((mapping) => pickBy({
                messageId: message.id,
                jobId: mapping.job ? mapping.jobId : null,
                materialId: mapping.material ? mapping.materialId : null,
                genotypeId: mapping.genotype ? mapping.genotypeId : null,
                lineId: mapping.line ? mapping.lineId : null,
                taskInstanceId: mapping.taskInstance ? mapping.taskInstanceId : null,
            }, (value) => value !== null))
        }) as MessageMapDraft[];
        await this.messageService.createMessageMap(initialValues);
    }
}
