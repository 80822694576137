import { createStore, createEvent, sample } from 'effector';

export interface ModernFacetsStore {
  initialized: boolean;
  facets: ModernFacet[];
}

export const $modernFacets = createStore<ModernFacetsStore>({
  initialized: false,
  facets: [],
});

// events
export const initEvent = createEvent<ModernFacet[]>();

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// Triggers
// expand event
sample({
  source: initEvent,
  fn: (facets) => ({ initialized: true, facets }),
  target: $modernFacets,
});

