import { map, switchMap } from 'rxjs/operators';
import { from, Observable } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
    EntityQuery,
    Predicate
} from 'breeze-client';

import { ENV_CONFIG, EnvironmentConfig } from '@config';
import { BaseEntityService } from './base-entity.service';
import { AdminManagerService } from './admin-manager.service';
import { AuthService } from './auth.service';
import { CurrentWorkgroupService } from './current-workgroup.service';
import { DataManagerService } from '../services/data-manager.service';
import { User, WorkgroupUser } from '@common/types/admincore.graphql.type';

export interface IRoleFacet {
    Facet: {
        Id: number;
        FacetDisplayName: string,
        FacetName: string;
    };
    HasReadAccess: boolean;
    HasWriteAccess: boolean;
}

type Role = {
    C_ClimbRole_key: number;
    RoleName: string;
};

export type RoleFacet = {
    id: number;
    facetName: string;
    facetDisplayName: string;
};

export type ClimbRoleFacet = {
    facetKey: number;
    hasReadAccess: boolean;
    hasWriteAccess: boolean;
    sortOrder: number;
};

export type ClimbRole = {
    id: number;
    roleName: string;
    climbRoleFacets: ClimbRoleFacet[];
};

type GQLResponse<T> = {
    data: T;
};

type ClimbRoles = {
    climbRoles: {
        nodes: ClimbRole[];
    };
};

type Facets = {
    facets: {
        nodes: RoleFacet[];
    };
};

type WorkgroupUsers = {
    workgroupUsers: {
        nodes: WorkgroupUser[];
    };
};

type ClimbRolesResponse = GQLResponse<ClimbRoles>;
type FacetsResponse = GQLResponse<Facets>;
type ClimbRolesFacetsResponse = GQLResponse<Facets & ClimbRoles>;
type UsersByRoleIdResponse = GQLResponse<WorkgroupUsers>;

const GQL_ROLES_QUERY = `
  query Roles {
    climbRoles(order: { roleName: ASC }) {
    nodes {
      id
      roleName
    }
  }
  }
`;

const GQL_FACETS_QUERY = `
  query Facets($roleName: String!) {
    facets {
      nodes {
        id
        facetName
        facetDisplayName
      }
    }
    climbRoles(where: { roleName: { eq: $roleName } }) {
      nodes {
        id
        roleName
        climbRoleFacets {
          facetKey
          hasReadAccess
          hasWriteAccess
          sortOrder
        }
      }
    }
  }
`;

const GQL_FACETS_BY_NAMES_QUERY = `
  query FacetsByNames($facetName: String!) {
    facets(where: { facetName: { eq: $facetName } }) {
      nodes {
        id
        facetName
      }
    }
  }
`;

const GQL_FACETS_BY_ROLE_QUERY = `
  query RolesByFacetKey($roleName: String!, $facetKey: Int!) {
    climbRoles(
      where: {
        roleName: { eq: $roleName }
        climbRoleFacets: { some: { facetKey: { eq: $facetKey } } }
      }
    ) {
      nodes {
        climbRoleFacets {
          hasReadAccess
          hasWriteAccess
          id
          facetKey
        }
      }
    }
  }
`;

const GQL_USERS_BY_ROLE_ID_QUERY = `
  query UsersByRoleId($climbRoleId: Int!) {
    workgroupUsers(where: { climbRoleId: { eq: $climbRoleId } }) {
      nodes {
        user {
          id
        }
      }
    }
  }
`;

const capitalize = (word: string) => word.charAt(0).toUpperCase() + word.slice(1);
const capitalizeKeys = <T extends Record<string, unknown>, R>(obj: T) => {
    return Object.keys(obj).reduce((acc, key) => {
        acc[capitalize(key)] = obj[key];
        return acc;
    }, {} as R);
};

@Injectable()
export class RoleService extends BaseEntityService {

    readonly ADMINISTRATOR_ROLE = 'Administrator';

    readonly ENTITY_TYPE = 'ClimbRoles';
    readonly ENTITY_NAME = 'ClimbRole';

    constructor(
        private adminManager: AdminManagerService,
        private authService: AuthService,
        private currentWorkgroupService: CurrentWorkgroupService,
        private dataManager: DataManagerService,
        private httpClient: HttpClient,
        @Inject(ENV_CONFIG) private config: EnvironmentConfig,
    ) {
        super();
    }

    get adminUrl(): string {
        return this.config.graphQlAdminUrl + 'graphql';
    }

    getRoles(): Promise<Role[]> {
        const adminUrl = this.config.graphQlAdminUrl + 'graphql';
        return this.httpClient.post<ClimbRolesResponse>(adminUrl, {
            operationName: 'Roles',
            query: GQL_ROLES_QUERY,
        }).pipe(
            map(({ data }) => data.climbRoles.nodes),
            map((roles) => roles.map((role) => ({
                C_ClimbRole_key: role.id,
                RoleName: role.roleName
            }))),
        ).toPromise();
    }

    // TODO: remove with the Role Facet
    _getRoles(): Promise<any[]> {
        const workgroupKey = this.currentWorkgroupService.getCurrentWorkgroupKey();
        const query = EntityQuery.from(this.ENTITY_TYPE)
            .expand('ClimbRoleFacets.Facet')
            .where('C_Workgroup_key', '==', workgroupKey)
            .orderBy('RoleName');

        return this.adminManager.returnQueryResults(query);
    }

    async getUserRole(): Promise<string> {
        await this.adminManager.init();

        const workgroupKey = this.currentWorkgroupService.getCurrentWorkgroupKey();
        const pWorkgroup = Predicate.create('C_Workgroup_key', '==', workgroupKey);

        const userId = this.authService.getCurrentUserId();
        const pUser = Predicate.create('C_User_key', '==', userId);

        const pred = Predicate.and([pWorkgroup, pUser]);

        const query = EntityQuery.from('WorkgroupUsers')
            .expand("ClimbRole")
            .select('ClimbRole.RoleName')
            .where(pred);

        return this.adminManager.returnSingleQueryResult(query).then((data) => {
            if (data) {
                return data.ClimbRole.RoleName;
            } else {
                return "";
            }
        }).catch(this.adminManager.queryFailed);
    }

    isAdministrator(): Promise<boolean> {
        return this.getUserRole().then((roleName) => {
            if (!roleName) {
                return false;
            }

            return roleName.toLowerCase() === this.ADMINISTRATOR_ROLE.toLowerCase();
        });
    }

    isStudyAdministrator(): Promise<boolean | void> {
        const workgroupKey = this.currentWorkgroupService.getCurrentWorkgroupKey();
        const pWorkgroup = Predicate.create('C_Workgroup_key', '==', workgroupKey);

        const userId = this.authService.getCurrentUserId();
        const pUser = Predicate.create('C_User_key', '==', userId);

        const pred = Predicate.and([pWorkgroup, pUser]);

        const query = EntityQuery.from('WorkgroupUsers')
            .select('StudyAdministrator')
            .where(pred)
            .noTracking(true);

        return this.adminManager.returnSingleQueryResult(query).then((data) => {
            return (data && data.StudyAdministrator === true);
        }).catch(this.adminManager.queryFailed);
    }

    getCurrentUserStudyAdministratorStudies(): Promise<any> {
        const userId = this.authService.getCurrentUserId();
        const pred = Predicate.create('C_User_key', '==', userId);

        const query = EntityQuery.from('StudyAdministratorStudies')
            .where(pred)
            .noTracking(true);

        return this.dataManager.returnQueryResults(query).then((data) => {
            return data;
        }).catch(this.dataManager.queryFailed);
    }

    getCurrentUserRoleFacets(): Observable<IRoleFacet[]> {
        const adminUrl = this.config.graphQlAdminUrl + 'graphql';

        return from(this.getUserRole()).pipe(
            switchMap((roleName) => this.httpClient.post<ClimbRolesFacetsResponse>(adminUrl, {
                operationName: 'Facets',
                query: GQL_FACETS_QUERY,
                variables: { roleName },
            })),
            map(({ data }) => {
                const climbRole = data.climbRoles.nodes[0];
                const facets = data.facets.nodes.reduce((acc, facet) => {
                    acc[facet.id] = capitalizeKeys<RoleFacet, IRoleFacet['Facet']>(facet);
                    return acc;
                }, {} as Record<string, IRoleFacet['Facet']>);
                return climbRole.climbRoleFacets
                    .sort((a, b) => a.sortOrder - b.sortOrder)
                    .map((roleFacet) => ({
                        ...capitalizeKeys<ClimbRoleFacet, IRoleFacet>(roleFacet),
                        Facet: facets[roleFacet.facetKey],
                    }));
            }),
        );
    }

    getUsersInRole(climbRoleId: number): Promise<User[]> {
        return this.httpClient.post<UsersByRoleIdResponse>(this.adminUrl, {
            operationName: 'UsersByRoleId',
            query: GQL_USERS_BY_ROLE_ID_QUERY,
            variables: { climbRoleId },
        }).pipe(
            map(({ data }) => data.workgroupUsers.nodes),
            map((workgroupUsers) => workgroupUsers.map((workgroupUser) => workgroupUser.user)),
        ).toPromise();
    }

    createRole(): Promise<any> {
        // Get ordered list of facets in this workgroup
        const workgroupKey = this.currentWorkgroupService.getCurrentWorkgroupKey();
        const query = EntityQuery.from('Facets')
            .where('C_Workgroup_key', '==', workgroupKey)
            .orderBy('SortOrder');

        return this.adminManager.returnQueryResults(query).then((facets: any[]) => {
            // Create role
            const roleInitialValues = {
                RoleName: '',
                DateCreated: new Date()
            };
            const newRole = this.adminManager.createEntity(this.ENTITY_NAME, roleInitialValues);

            // Add ClimbRoleFacet to new role for each facet
            let sortOrder = 1;
            for (const facet of facets) {
                const facetInitialValues = {
                    C_Role_key: newRole.C_Role_key,
                    C_Facet_key: facet.C_Facet_key,
                    HasReadAccess: false,
                    HasWriteAccess: false,
                    SortOrder: sortOrder
                };
                const newRoleFacet = this.adminManager.createEntity(
                    'ClimbRoleFacet',
                    facetInitialValues
                );
                newRoleFacet.Facet = facet;

                newRole.ClimbRoleFacets.push(newRoleFacet);

                sortOrder++;
            }

            return newRole;
        }).catch(this.adminManager.queryFailed);
    }

    deleteRole(role: any) {
        while (role.ClimbRoleFacets.length > 0) {
            const roleFacet = role.ClimbRoleFacets[0];
            this.adminManager.deleteEntity(roleFacet);
        }

        this.adminManager.deleteEntity(role);
    }

    getFacetPrivilegeByRole(facetName: string, roleName: string): Promise<Omit<IRoleFacet, 'Facet'>> {
        const adminUrl = this.config.graphQlAdminUrl + 'graphql';

        return this.httpClient.post<FacetsResponse>(adminUrl, {
            operationName: 'FacetsByNames',
            query: GQL_FACETS_BY_NAMES_QUERY,
            variables: { facetName },
        }).pipe(
            map(({ data }) => data.facets.nodes[0]),
            switchMap((roleFacet) => {
                return this.httpClient.post<ClimbRolesResponse>(adminUrl, {
                    operationName: 'RolesByFacetKey',
                    query: GQL_FACETS_BY_ROLE_QUERY,
                    variables: { roleName, facetKey: roleFacet.id },
                }).pipe(
                    map(({ data }) => {
                        return data.climbRoles.nodes[0].climbRoleFacets
                            .filter(({ facetKey }) => facetKey === roleFacet.id)
                            .shift()
                    }),
                )
            }),
            map((roleFacet) => capitalizeKeys<ClimbRoleFacet, Omit<IRoleFacet, 'Facet'>>(roleFacet)),
        ).toPromise();
    }
}
