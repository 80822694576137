import {
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';

import { DragulaService } from 'ng2-dragula';
import { SaveChangesService } from './../services/save-changes.service';
import { RoleService } from '../services/role.service';
import { DataContextService } from '../services/data-context.service';

import { reorderOnDropModel } from '../common/util/reorder-on-drop-model';
import { sortObjectArrayByProperty } from '../common/util/sort-array';

import {
    notEmpty,
    randomId
} from '../common/util';
import { arrowClockwise } from '@icons';
import { IFacet } from '../common/facet';
import { Entity } from '../common/types';
import { Role, RoleFacet } from '../common/types/models/role.interface';

interface RoleExtended extends Role {
    isSelected: boolean;
}

@Component({
    selector: 'roles-facet',
    templateUrl: './roles-facet.component.html',
})
export class RolesFacetComponent implements OnInit, OnDestroy {
    @Input() facetId: string;
    @Input() facet: IFacet;
    @ViewChild('rolesPanelBody') rolesPanelBody: ElementRef;

    readonly icons = { arrowClockwise };

    roles: Entity<RoleExtended>[] = [];
    selectedRole: Entity<RoleExtended> = null;
    dangerMessage: string = null;
    dragulaBagName: string;

    readonly COMPONENT_LOG_TAG = 'roles-facet';

    constructor(
        private saveChangesService: SaveChangesService,
        private dragulaService: DragulaService,
        private roleService: RoleService,
        private dataContext: DataContextService
    ) { }

    ngOnInit() {
        this.dragulaBagName = randomId() + '-bag';

        this.refreshData().then((data) => {
            this.selectFirstRole();
        });

        this.dragulaService.dropModel().subscribe(({ targetModel }) => {
            this.selectedRole.ClimbRoleFacets.sort((a: RoleFacet, b: RoleFacet) => {
                const aIndex = targetModel.findIndex((task: RoleFacet) => task.C_ClimbRoleFacet_key === a.C_ClimbRoleFacet_key);
                const bIndex = targetModel.findIndex((task: RoleFacet) => task.C_ClimbRoleFacet_key === b.C_ClimbRoleFacet_key);
                return aIndex - bIndex;
            });
            reorderOnDropModel(this.selectedRole.ClimbRoleFacets);
        });

        // Disable dragging if can't edit
        if (this.facet.Privilege !== 'ReadWrite') {
            this.dragulaService.createGroup(this.dragulaBagName, {
                moves: (el: Element, source: Element, handle: Element, sibling: Element) => false
            });
        }

        this.dataContext.onCancel$.subscribe(() => {
            this.refreshData().then((data) => {
                this.selectFirstRole();
            });
        });
    }

    ngOnDestroy(): void {
        if (!!this.dragulaService.find(this.dragulaBagName)) {
            this.dragulaService.destroy(this.dragulaBagName);
        }
    }

    refreshData() {
        return this.roleService._getRoles().then((data) => {
            this.roles = data;
        });
    }

    private scrollToTop() {
        if (this.rolesPanelBody && this.rolesPanelBody.nativeElement) {
            jQuery(this.rolesPanelBody.nativeElement).animate({ scrollTop: 0 }, "fast");
        }
    }

    removeRole(role: Entity<RoleExtended>) {
        this.roleService.deleteRole(role);

        const roleIndex = this.roles.indexOf(role);
        this.roles.splice(roleIndex, 1);
    }

    addRole() {
        this.roleService.createRole().then((role: Entity<RoleExtended>) => {
            this.roles.push(role);
            this.selectRole(role);
        });
    }

    selectRole(roleToSelect: Entity<RoleExtended>) {
        this.selectedRole = roleToSelect;

        for (const role of this.roles) {
            role.isSelected = false;
        }
        roleToSelect.isSelected = true;

        this.scrollToTop();
    }

    selectFirstRole() {
        if (notEmpty(this.roles)) {
            // Select first role by default
            this.selectRole(this.roles[0]);
        }
    }


    writeAccessChange(roleFacet: RoleFacet, hasWriteAccess: boolean) {
        if (hasWriteAccess) {
            roleFacet.HasReadAccess = true;
        }
    }

    get facetEditable() {
        return this.facet.Privilege !== 'ReadOnly';
    }

    get sortedClimbRoleFacets() {
        if (this.selectedRole && this.selectedRole.ClimbRoleFacets) {
            return sortObjectArrayByProperty(
                this.selectedRole.ClimbRoleFacets,
                'SortOrder');
        } else {
            return [];
        }
    }

    onSaveRole() {
        this.saveChangesService.saveChanges(this.COMPONENT_LOG_TAG);
    }
}
