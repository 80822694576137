import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewChildren
} from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import {
    arrayContainsAllObjects,
    empty,
    getSafeProp,
    maxSequence,
    notEmpty,
    randomId,
    sortObjectArrayByAccessor,
    summarizeItems,
    testBreezeIsNew,
    uniqueArrayFromPropertyPath,
} from '@common/util';

import { ClinicalService } from '../clinical/clinical.service';
import { EnumerationService } from '../enumerations/enumeration.service';
import { EntityChangeService } from '../entity-changes/entity-change.service';
import { ExportJobDetailService } from './export-job-detail.service';
import { JobService } from './job.service';
import { JobLogicService } from './job-logic.service';
import { JobVocabService } from './job-vocab.service';
import { MessagePanelService } from '../messages/message-panel.service';
import { PrivilegeService } from '@services/privilege.service';
import { SaveChangesService, IValidatable } from '@services/save-changes.service';
import { TranslationService } from '@services/translation.service';
import { ViewJobAuditReportComponentService } from './view-job-audit-report-component.service';

import { TaskType } from '../tasks/models';

import {
    BaseDetail,
    BaseDetailService,
    FacetView,
    IFacet,
    PageState
} from '@common/facet';

import {
    AnimalSummaryItem,
    SampleSummaryItem
} from './models';
import {
    CountSummary,
    TableSort
} from '@common/models';

import { DetailTaskTableComponent, DetailTaskTableOptions } from '../tasks/tables';
import { NamingService } from '@services/naming.service';
import { OrderService } from '../orders/order.service';
import { LineService } from '../lines/line.service';
import { defer, forkJoin, from, Observable, of, Subscription } from 'rxjs';
import { SettingService } from '../settings/setting.service';
import { FeatureFlagService } from '@services/feature-flags.service';
import { ClinicalObservationDetail, Cohort, cv_Compliance, cv_IACUCProtocol, cv_JobStatus, cv_JobType, Entity, Job, JobMaterial, Site } from '../common/types';
import { dateControlValidator } from '@common/util/date-control.validator';
import { CharacteristicInputComponent } from '../characteristics/characteristic-input/characteristic-input.component';
import { catchError, concatMap, take, tap } from 'rxjs/operators';
import { MessageCenterPanelComponent } from '../messages/message-center-panel.component';
import { DialogService } from '@common/dialog/dialog.service';
import { Overlay } from '@angular/cdk/overlay';

interface ExtendedJob extends Job {
    FilteredAnimalMaterials: JobMaterial[];
    FilteredSampleMaterials: JobMaterial[];
    DerivedCohorts: Cohort[];
    AnimalSummary: CountSummary<AnimalSummaryItem>[];
    SampleSummary: CountSummary<SampleSummaryItem>[];
    JobMaterial: ExtendedJobMaterial[];
    animalsExpanded: boolean;
    cohortsExpanded: boolean;
    samplesExpanded: boolean;
    tasksExpanded: boolean;
    deviationExpanded: boolean;
    inputsExpanded: boolean;
    taskAnimalsExpanded: boolean;
    taskCohortsExpanded: boolean;
    taskSamplesExpanded: boolean;
}

export interface ExtendedJobMaterial extends JobMaterial {
    clinicalHistory: ClinicalObservationDetail[];
}

@Component({
    selector: 'job-detail',
    templateUrl: './job-detail.component.html'
})
export class JobDetailComponent extends BaseDetail
    implements OnInit, OnChanges, OnDestroy, IValidatable {
    @Input() facet: IFacet;
    @Input() facetView: FacetView;
    @Input() job: Entity<ExtendedJob>;
    @Input() pageState: PageState;

    @Output() exit: EventEmitter<void> = new EventEmitter<void>();
    @Output() next: EventEmitter<void> = new EventEmitter<void>();
    @Output() previous: EventEmitter<void> = new EventEmitter<void>();
    @Output() modelCopy: EventEmitter<any> = new EventEmitter<any>();

    @ViewChildren('characteristicInput') characteristicInputs: CharacteristicInputComponent[];
    @ViewChildren('detailTaskTable') detailTaskTables: DetailTaskTableComponent[];
    @ViewChildren('dateControl') dateControl: NgModel[];
    @ViewChild('jobForm') jobForm: NgForm;

    readonly NO_COHORT: string = 'No cohort';
    readonly NO_SEX: string = 'No sex';
    readonly NO_STRAIN: string = 'No strain';
    readonly NO_TYPE: string = 'No type';

    readwrite: boolean;
    readonly: boolean;
    studyAdministrator = false;
    jobNamingActive = false;
    originalJobName = '';
    isGLP: boolean;
    allowNoMaterial = false;

    // CVs
    jobStatuses: Entity<cv_JobStatus>[] = [];
    jobTypes: Entity<cv_JobType>[] = [];
    iacucProtocols: Entity<cv_IACUCProtocol>[] = [];
    compliances: Entity<cv_Compliance>[] = [];

    sites: Entity<Site>[] = [];

    // Active and required fields set by facet settings
    activeFields: string[] = [];
    requiredFields: string[] = [];

    // Table options
    detailTaskTableOptions: DetailTaskTableOptions;

    // Table sorting
    animalTableSort: TableSort = new TableSort();
    sampleTableSort: TableSort = new TableSort();
    taskTableSort: TableSort = new TableSort();

    breezeChangeEvent: any;
    breezeUpdateInProgress: any;

    readonly detailId = randomId();
    readonly printDivId = 'print-job-wrapper-' + this.detailId;

    readonly COMPONENT_LOG_TAG = 'job-detail';

    // Export enum to template
    TaskType = TaskType;

    _subs: Subscription[] = [];
    
    constructor(
        baseDetailService: BaseDetailService,
        private clinicalService: ClinicalService,
        private enumerationService: EnumerationService,
        private entityChangeService: EntityChangeService,
        private exportJobDetailService: ExportJobDetailService,
        private jobService: JobService,
        private jobLogicService: JobLogicService,
        private jobVocabService: JobVocabService,
        private messagePanelService: MessagePanelService,
        private namingService: NamingService,
        private privilegeService: PrivilegeService,
        private saveChangesService: SaveChangesService,
        private translationService: TranslationService,
        private viewJobAuditReportComponentService: ViewJobAuditReportComponentService,
        private orderService: OrderService,
        private lineService: LineService,
        private settingService: SettingService,
        private featureFlagService: FeatureFlagService,
        private dialogService: DialogService,
        private overlay: Overlay,
    ) {
        super(baseDetailService);
    }

    ngOnInit() {
        this.saveChangesService.registerValidator(this);
        this.initGLP();
        this.setUpBreezeChangeDetection();
        this.initialize();
        this.initTableOptions();
    }

    private initGLP() {
        const flag = this.featureFlagService.getFlag("IsGLP");
        this.isGLP = (flag && flag.IsActive && flag.Value.toLowerCase() === "true");
    }

    ngOnChanges(changes: any) {
        if (changes.job) {
            if (this.job && !changes.job.firstChange) {
                if (this.jobForm) {
                    this.jobForm.form.markAsPristine();
                }
                this.initialize();
            }
        }
    }

    ngOnDestroy() {
        this.saveChangesService.unregisterValidator(this);

        this.removeBreezeChangeDetection();
        for (const s of this._subs) {
            s.unsubscribe();
        }
    }

    setUpBreezeChangeDetection() {
        // refresh all grid cells on any breeze entity change
        this.breezeChangeEvent = this.entityChangeService
            .onAnyChange((changes: any) => {

                // We don't want to call this
                //   100x times if many entities just got loaded.
                // Set a timeout to give some breathing room.
                clearTimeout(this.breezeUpdateInProgress);
                this.breezeUpdateInProgress = setTimeout(() => {
                    this.onBreezeUpdate();
                }, 300);

            });
    }

    removeBreezeChangeDetection() {
        if (this.breezeChangeEvent) {
            this.breezeChangeEvent.unsubscribe();
        }
    }

    onBreezeUpdate() {
        this.setDerivedValues();
    }

    initialize() {
        this.setPrivileges();
        this.setDerivedValues();
        if (this.job) {
            this.setTableStates();
            this.originalJobName = this.job.JobID;
        }
        from(this.getStudyAdminStatus()).pipe(
            concatMap(() => forkJoin([
                from(this.settingService.getFacetSettingsByType('job', false, undefined))
                .pipe(tap(facetSettings => {
                    this.activeFields = this.settingService.getActiveFields(facetSettings);
                    this.requiredFields = this.settingService.getRequiredFields(facetSettings);
                })),
                this.getCVs(),
                from(this.getSites(this.job.C_Institution_key)),
                from(this.isNamingActive())
            ]))
        ).pipe(take(1)).subscribe();
        this.getJobDetails().subscribe();
    }

    private isNamingActive(): Promise<void> {
        return this.namingService.isJobNamingActive().then((active: boolean) => {
            this.jobNamingActive = active;
        });
    }

    private initTableOptions() {
        // Detail Task Table
        this.detailTaskTableOptions = new DetailTaskTableOptions();
        this.detailTaskTableOptions.allowLocking = true;
        this.detailTaskTableOptions.showAnimals = true;
        this.detailTaskTableOptions.showSamples = true;
    }

    /**
     * Sets privilege variables.
     */
    private setPrivileges() {
        this.readonly = this.privilegeService.readonly;
        this.readwrite = this.privilegeService.readwrite;
    }

    setDerivedValues() {
        this.setFilteredMaterials();
        this.setCohorts();
        this.setCountSummaries();
    }


    private getJobTasks() {
        let extraExpands: string[] = [];
        if (!this.job.IsHighThroughput) {
            // outputs and output materials 
            // are too slow to load for high-throughput jobs
            // This breaks display of animals in Job export
            // This breaks recalculation of outputs for job Input changes
            extraExpands = [
                'TaskOutputSet.TaskOutputSetMaterial.Material.Animal',
                /* tslint:disable-next-line */
                'TaskOutputSet.TaskOutput.Output.CalculatedOutputExpression.ExpressionOutputMapping',
                /* tslint:disable-next-line */
                'TaskOutputSet.TaskOutput.Output.CalculatedOutputExpression.ExpressionInputMapping',
            ];
        }
        return this.jobService.getJobTasks(
            this.job.C_Job_key, extraExpands
        );
    }

    private getJobDetails(): Observable<Entity<ExtendedJob> | unknown> {
        if (this.job && this.job.C_Job_key > 0) {
            this.setLoading(true);
            const expands: string[] = [                
                'JobLocation'
            ];
            return forkJoin([
                defer(() => this.jobService.getJob(this.job.C_Job_key, expands)),
                defer(() => this.jobService.getJobMaterials(this.job.C_Job_key)),
                this.jobService.ensureJobCohorts(this.job),
                this.getJobTasks().pipe(concatMap(jobTasks => {
                    return from(this.attachInputEnumerationsToJobTasks(jobTasks));
                })),
                 from(this.jobService.getJobCharacteristics(
                    this.job.C_Job_key
                )),
                from(this.attachCharacteristicEnumerations(
                    this.job.JobCharacteristicInstance
                ))
            ]).pipe(
                // this.job.jobMaterial should not be empty
                tap(() => this.attachObservationDetailsToJobMaterials(this.job.JobMaterial)),
                tap(() => {
                    this.setLoading(false); 
                }),
                catchError(err => {
                    this.setLoading(false);
                    return err;
                }),
                take(1)
            );
        }
        return of(undefined).pipe(take(1));
    }

    private getStudyAdminStatus(): Promise<void> {
        return this.privilegeService.getCurrentUserStudyAdministratorStudies().then((studyAdminStudies: any[]) => {
            if (this.job.C_Study_key !== null) {
                this.studyAdministrator = studyAdminStudies.find((x) => x.C_Study_key === this.job.C_Study_key) != null;
            } else {
                this.studyAdministrator = studyAdminStudies.length > 0;
            }
        });
    }

    onStudyChange() {
        this.getStudyAdminStatus();
    }

    setFilteredMaterials() {
        const jobMaterials = this.job.JobMaterial;
        if (notEmpty(jobMaterials)) {
            const filteredAnimals = jobMaterials.filter((item: JobMaterial) => {
                return getSafeProp(item, 'Material.Animal');
            });
            if (!arrayContainsAllObjects(this.job.FilteredAnimalMaterials, filteredAnimals)) {
                this.job.FilteredAnimalMaterials = filteredAnimals;
            }
            const filteredSamples = jobMaterials.filter((item: JobMaterial) => {
                return getSafeProp(item, 'Material.Sample');
            });
            if (!arrayContainsAllObjects(this.job.FilteredSampleMaterials, filteredSamples)) {
                this.job.FilteredSampleMaterials = filteredSamples;
            }
        } else {
            this.job.FilteredAnimalMaterials = [];
            this.job.FilteredSampleMaterials = [];
        }

        if (!this.job.FilteredAnimalMaterials) {
            this.job.FilteredAnimalMaterials = [];
        }
        if (!this.job.FilteredSampleMaterials) {
            this.job.FilteredSampleMaterials = [];
        }
    }

    /**
     * Set DerivedCohorts on job
     * Contains cohorts for all animals in the job
     */
    setCohorts() {
        this.job.DerivedCohorts = uniqueArrayFromPropertyPath(
            this.job,
            'JobCohort.Cohort'
        );
    }

    /**
     * Set job.AnimalSummaries
     * Set job.SampleSummaries
     */
    setCountSummaries() {
        let animalSummaries: CountSummary<AnimalSummaryItem>[] = [];
        let sampleSummaries: CountSummary<SampleSummaryItem>[] = [];

        if (notEmpty(this.job.FilteredAnimalMaterials)) {
            const animals = uniqueArrayFromPropertyPath(
                this.job.FilteredAnimalMaterials,
                'Material.Animal'
            );
            const items: AnimalSummaryItem[] = [];
            for (const animal of animals) {
                let cohortNames = uniqueArrayFromPropertyPath(
                    animal,
                    'Material.CohortMaterial.Cohort.CohortName'
                );
                if (cohortNames.length === 0) {
                    cohortNames = [this.NO_COHORT];
                }

                const sex = getSafeProp(animal, 'cv_Sex.Sex') || this.NO_SEX;
                const strain = getSafeProp(animal, 'Material.Line.LineName') || this.NO_STRAIN;

                for (const cohortName of cohortNames) {
                    const item = new AnimalSummaryItem({
                        cohortName,
                        sex,
                        strain
                    });
                    items.push(item);
                }
            }

            animalSummaries = summarizeItems(items);
        }

        if (notEmpty(this.job.FilteredSampleMaterials)) {
            const samples = uniqueArrayFromPropertyPath(
                this.job.FilteredSampleMaterials,
                'Material.Sample'
            );
            const items: SampleSummaryItem[] = [];
            for (const sample of samples) {

                const type = getSafeProp(sample, 'cv_SampleType.SampleType') || this.NO_TYPE;
                const strain = getSafeProp(sample, 'Material.Line.LineName') || this.NO_STRAIN;

                const item = new SampleSummaryItem({
                    type,
                    strain
                });
                items.push(item);
            }

            sampleSummaries = summarizeItems(items);
        }

        this.job.AnimalSummary = animalSummaries;
        this.job.SampleSummary = sampleSummaries;
    }

    private async attachObservationDetailsToJobMaterials(jobMaterials: ExtendedJobMaterial[]): Promise<void> {

        const animalMaterials = jobMaterials.filter((item: ExtendedJobMaterial) => {
            return getSafeProp(item, 'Material.Animal');
        });

        await this.clinicalService.mapDetailsClinicalHistory(animalMaterials);
    }

    private attachInputEnumerationsToJobTasks(jobTasks: any[]) {
        const promises = [];

        for (const jobTask of jobTasks) {
            const promise = this.enumerationService.attachInputEnumerations(jobTask.TaskInput);
            promises.push(promise);
        }

        return Promise.all(promises);
    }

    private attachCharacteristicEnumerations(characteristicInstances: any[]) {
        const promises = [];

        for (const characteristicInstance of characteristicInstances) {
            if (characteristicInstance.JobCharacteristic.C_EnumerationClass_key) {
                const promise = this.enumerationService.getEnumerationItems(
                    characteristicInstance.JobCharacteristic.C_EnumerationClass_key
                ).then((items: any[]) => {
                    characteristicInstance.EnumerationItems = items;
                });

                promises.push(promise);
            }
        }

        return Promise.all(promises);
    }

    private getCVs(): Observable<[Entity<cv_JobType>[], Entity<cv_JobStatus>[], Entity<cv_IACUCProtocol>[], Entity<cv_Compliance>[]]> {
        return forkJoin([
            this.jobVocabService.jobTypes$.pipe(tap(jobTypes => {
                this.jobTypes = jobTypes;
            })),
            this.jobVocabService.jobStatuses$.pipe(tap(jobStatuses => {
                this.jobStatuses = jobStatuses;
            })),
            this.jobVocabService.iacucProtocols$.pipe(tap(iacucProtocols => {
                this.iacucProtocols = iacucProtocols;
            })),
            this.jobVocabService.compliances$.pipe(tap(compliances => {
                this.compliances = compliances;
            }))
        ]).pipe(take(1));
        
    }

    highThroughputChanged() {
        this.setTableStates();
    }

    /**
     * Sets view defaults for tables
     */
    setTableStates() {
        // High throughput hides animals and shows cohorts
        const isHighThroughput: boolean = this.job.IsHighThroughput;
        this.job.animalsExpanded = !isHighThroughput;
        this.job.cohortsExpanded = isHighThroughput;
        this.job.samplesExpanded = !isHighThroughput;

        this.job.tasksExpanded = true;
        this.job.deviationExpanded = false;
        this.job.inputsExpanded = !isHighThroughput;
        this.job.taskAnimalsExpanded = !isHighThroughput;
        this.job.taskCohortsExpanded = !this.job.taskAnimalsExpanded;
        this.job.taskSamplesExpanded = !isHighThroughput;
    }

    onCancel() {
        this.jobService.cancelJob(this.job);
    }

    copyJob() {
        this.saveChangesService.promptForUnsavedChanges(this.COMPONENT_LOG_TAG).then(() => {
            const fromJob = this.job;

            // Copy from current job to a newly created job
            this.jobService.createJob().then((newJob: any) => {
                const toJob = newJob;

                // Copy values
                this.jobService.copyJob(fromJob, toJob).then(() => {

                    // Set the current job to the newly created job
                    this.job = toJob;

                    // Set derived characteristics and tasks values
                    // (this mimics edit job, some of this could be refactored out)
                    this.attachCharacteristicEnumerations(this.job.JobCharacteristicInstance);

                    this.job.TaskJob = toJob.TaskJob;
                    for (const taskJob of this.job.TaskJob) {
                        this.enumerationService.attachInputEnumerations(
                            taskJob.TaskInstance.TaskInput
                        );
                    }

                    this.modelCopy.emit(this.job);
                });
            });
        });
    }

    printJob() {
        const printContents = document.getElementById(this.printDivId).innerHTML;
        
        let popupWin: any;

        if (navigator.userAgent.toLowerCase().indexOf('chrome') > -1) {
            popupWin = window.open(
                '',
                '_blank',
                'width=600,height=600,' +
                'scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no'
            );
            popupWin.window.focus();
            popupWin.document.write(
                '<!DOCTYPE html>' +
                '<html><head>' +
                '<link rel="stylesheet" type="text/css" href="/css/main.css" />' +
                '</head><body onload="window.print()">' +
                printContents +
                '</div></html>'
            );
            popupWin.onbeforeunload = (event: any) => {
                popupWin.close();
                return '.\n';
            };
            popupWin.onabort = (event: any) => {
                popupWin.document.close();
                popupWin.close();
            };
        } else {
            popupWin = window.open('', '_blank', 'width=800,height=600');
            popupWin.document.open();
            popupWin.document.write(
                '<html>' +
                '<head><link rel="stylesheet" type="text/css" href="/css/main.css" /></head>' +
                '<body onload="window.print()">' +
                printContents +
                '</html>'
            );
        }
        popupWin.document.close();

        return true;
    }

    typeChanged(job: any) {
        if (this.job.C_JobType_key) {
            this.updateJobName('type');
        }
        const numRemainingCharacteristics = this.job.JobCharacteristicInstance.filter((jci: any) => {
            return getSafeProp(jci, 'JobCharacteristic.cv_JobCharacteristicLinkType.JobCharacteristicLinkType') !== 'Job Type';
        }).length;
        while (this.job.JobCharacteristicInstance.length > numRemainingCharacteristics) {
            const jobCharacteristicInstance = this.job.JobCharacteristicInstance.find((jci: any) => {
                return getSafeProp(jci, 'JobCharacteristic.cv_JobCharacteristicLinkType.JobCharacteristicLinkType') === 'Job Type';
            });
            this.jobService.deleteJobCharacteristic(jobCharacteristicInstance);
        }

        this.jobService.createJobTypeCharacteristics(job).then((data: any[]) => {
            this.attachCharacteristicEnumerations(this.job.JobCharacteristicInstance);
        });
    }

    jobStatusChanged() {
        if (this.job) {
            this.jobService.jobStatusChanged(this.job, false, this.facet.Privilege === 'ReadOnly');
        }
    }

    getSites(institutionKey: number): Promise<Entity<Site>[]> {
        return this.orderService.getInstitutionSites(institutionKey).then((data) => {
            this.sites = data;
            return data;
        });
    }

    institutionChanged(institutionKey: number) {
        this.job.C_Site_key = null;
        this.getSites(institutionKey);
    }

    jobCodeChanged() {
        if (this.job.JobCode) {
            this.updateJobName('code');
        }
    }

    iacucProtocolChanged() {
        if (this.job.C_IACUCProtocol_key) {
            this.updateJobName('iacuc protocol');
        }

        // Update characteristics
        const numRemainingCharacteristics = this.job.JobCharacteristicInstance.filter((jci: any) => {
            return getSafeProp(jci, 'JobCharacteristic.cv_JobCharacteristicLinkType.JobCharacteristicLinkType') !== 'IACUC Protocol';
        }).length;

        while (this.job.JobCharacteristicInstance.length > numRemainingCharacteristics) {
            const jobCharacteristicInstance = this.job.JobCharacteristicInstance.find((jci: any) => {
                return getSafeProp(jci, 'JobCharacteristic.cv_JobCharacteristicLinkType.JobCharacteristicLinkType') === 'IACUC Protocol';
            });
            this.jobService.deleteJobCharacteristic(jobCharacteristicInstance);
        }

        this.jobService.createJobIacucCharacteristics(this.job).then((data: any[]) => {
            this.attachCharacteristicEnumerations(this.job.JobCharacteristicInstance);
        });
    }

    complianceChanged() {
        if (this.job.C_Compliance_key) {
            this.updateJobName('compliance');
        }
    }

    async validate(): Promise<string> {
        const translatedJob = this.translationService.translate('Job');

        // Check that auto-naming field has value
        if (this.jobNamingActive && testBreezeIsNew(this.job)) {
            const invalidField = await this.jobLogicService.validateJobNamingField(this.job);
            if (invalidField) {
                return `The ${this.translationService.translate(invalidField)} field is required for automatic naming.`;
            }
        } else if (empty(this.job.JobID)) {
            return `A ${translatedJob} requires a Name.`;
        }

        if (empty(this.job.C_JobStatus_key) || this.job.C_JobStatus_key === 0) {
            return `A ${translatedJob} requires a Status.`;
        }

        if (empty(this.job.C_JobType_key) || empty(this.job.cv_JobType)) {
            return `A ${translatedJob} requires a Type.`;
        }

        const testArticles = (this.job.JobTestArticle ?? []).filter((item: any) => !item.cv_TestArticle?.IsSystemGenerated);
        const jobTestArticleValid = testArticles.every((item: any) => item.C_TestArticle_key);


        const errMessage = dateControlValidator(this.dateControl)
            || this.characteristicInputs.map(input => input.validate()).find(msg => msg)
            || this.detailTaskTables.map(input => input.validate()).find(msg => msg);
        if (errMessage) {
            return errMessage;
        }

        if (!jobTestArticleValid) {
            return `Ensure that all required fields within Test Articles are filled.`;
        }

        const jobInstitutionValid = (this.job.JobInstitution ?? []).every((item: any) => item.C_Institution_key);

        if (!jobInstitutionValid) {
            return `Ensure that all required fields within ${this.translationService.translate('Institutions')} are filled.`;
        }

        const jobLocationValid = (this.job.JobLocation ?? []).every((item: any) => item.LocationPosition);

        if (!jobLocationValid) {
            return `Ensure that all required fields within Locations are filled.`;
        }

        return '';
    }

    updateJobName(field: string) {
        // Apply new number only if is an update
        if (this.job.JobID) {
            this.jobService.getJobPrefixField().then((jobPrefixField: string) => {
                if (jobPrefixField.toLowerCase() === field.toLowerCase()) {
                    // Automatically regenerate JobID
                    this.jobService.autoGenerateJobID(this.job).then((newID: string) => {
                        if (newID !== this.job.JobID) {
                            this.job.JobID = newID;
                            // Alert user of automatic change
                            this.loggingService.logWarning(
                                `The Name field has been automatically changed due to changing the ${this.translationService.translate(jobPrefixField)} field.`,
                                null, this.COMPONENT_LOG_TAG, true);
                        }
                    });
                }
            });
        }
    }

    // Locking
    onJobLockChange() {
        // Lock all tasks when the job is locked
        if (this.job.IsLocked === true) {
            this.lockAllTasks();
        }

        // Do nothing when the job is unlocked
    }

    private lockAllTasks() {
        for (const taskJob of this.job.TaskJob) {
            taskJob.TaskInstance.IsLocked = true;
        }
    }


    // Cost
    calculateJobCost() {
        if (notEmpty(this.job.TaskJob)) {
            const sum = this.job.TaskJob
                .reduce((prevValue: number, taskJob: any) => {
                    const newValue: number = taskJob.TaskInstance.Cost;

                    if (newValue) {
                        return prevValue + newValue;
                    } else {
                        // Ignore tasks with no value
                        return prevValue;
                    }
                }, 0);

            if (sum > 0) {
                this.job.Cost = sum;
            } else {
                this.loggingService.logWarning(
                    "Cannot calculate cost: " +
                    "please specify or calculate task costs first.",
                    null,
                    this.COMPONENT_LOG_TAG,
                    true
                );
            }
        } else {
            this.loggingService.logWarning(
                "The cost cannot be calculated: there are no associated tasks.",
                null,
                this.COMPONENT_LOG_TAG,
                true
            );
        }
    }

    // Duration
    calculateJobDuration() {
        if (notEmpty(this.job.TaskJob)) {
            const sum = this.job.TaskJob
                .reduce((prevValue: number, taskJob: any) => {
                    const newValue: number = taskJob.TaskInstance.Duration;

                    if (newValue) {
                        return prevValue + newValue;
                    } else {
                        // Ignore tasks with no value
                        return prevValue;
                    }
                }, 0);

            if (sum > 0) {
                this.job.Duration = sum;
            } else {
                this.loggingService.logWarning(
                    "Cannot calculate duration: " +
                    "please specify or calculate task durations first.",
                    null,
                    this.COMPONENT_LOG_TAG,
                    true
                );
            }
        } else {
            this.loggingService.logWarning(
                "The duration cannot be calculated: there are no associated tasks.",
                null,
                this.COMPONENT_LOG_TAG,
                true
            );
        }
    }


    // Materials
    addMaterialToJob<T extends { C_Material_key: number }>(material: T) {
        if (!material) {
            return;
        }

        const newJobMaterial = this.jobService.createJobMaterial({
            C_Job_key: this.job.C_Job_key,
            C_Material_key: material.C_Material_key
        });

        if (newJobMaterial) {
            newJobMaterial.isSelected = false;
        }
    }


    // Tasks
    addTaskJob<T extends { C_TaskInstance_key: number }>(taskInstance: T) {
        const taskSequence = maxSequence(this.job.TaskJob) + 1;

        this.jobService.createTaskJob(
            this.job.C_Job_key,
            taskInstance.C_TaskInstance_key,
            taskSequence
        );
    }


    /**
     * Attaches the job to a new message
     */
    newMessage(): void {
        const subject = `${this.translationService.translate('Job')}: ${this.job.JobID}`;

        const mapping = {
            jobId: this.job.C_Job_key,
            job: { ...this.job, jobId: this.job.JobID },
        };
        const messageMaps = [mapping];

        this.messagePanelService.openNewMessage({
            messageSubject: subject,
            messageMaps
        });
        this.dialogService.open(MessageCenterPanelComponent, { positionStrategy: this.overlay.position().global().right('0'), data: {
            messageSubject: subject,
            messageMaps
        } });
    }

    exportJob() {
        this.exportJobDetailService.exportJobToCsv(
            this.job,
            this.animalTableSort,
            this.sampleTableSort,
            this.taskTableSort,
            false, // Studies 1 arent crl/cro
            this.isGLP
        );
    }

    viewAuditReport() {
        this.viewJobAuditReportComponentService.openComponent(this.job.C_Job_key, false, false, false, false);
    }

    addTestArticle(job: any) {
        const initialValues = {
            C_Job_key: job.C_Job_key,
        };
        this.jobService.createJobTestArticle(initialValues);
    }

    // <select> formatters
    jobStatusKeyFormatter = (value: any) => {
        return value.C_JobStatus_key;
    }
    jobStatusFormatter = (value: any) => {
        return value.JobStatus;
    }
    jobTypeKeyFormatter = (value: any) => {
        return value.C_JobType_key;
    }
    jobTypeFormatter = (value: any) => {
        return value.JobType;
    }
    iacucProtocolKeyFormatter = (value: any) => {
        return value.C_IACUCProtocol_key;
    }
    iacucProtocolFormatter = (value: any) => {
        return value.IACUCProtocol;
    }
    complianceKeyFormatter = (value: any) => {
        return value.C_Compliance_key;
    }
    complianceFormatter = (value: any) => {
        return value.Compliance;
    }

    lineChanged() {
        if (this.job.C_Line_key) {
            this.lineService.getLine(this.job.C_Line_key, ['cv_Taxon']);
            this.updateJobName('line');
        }
    }
}
