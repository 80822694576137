import { AuditProperties, Message } from '@common/types/admincore.graphql.type';

/**
 * Represent a Message from Admin DB,
 *   when used in the message center inbox
 */
export type InboxMessage = Message & {
    messageMaps: MessageMap[];
};

export type UserBasicInfo = {
    firstName: string;
    id: string;
    lastName: string;
};

export type MessageMap = {
    animalHealthRecordId: number;
    audit: AuditProperties;
    genotypeId: number;
    genotype?: {
        cvGenotypeAssay: {
            genotypeAssay: string;
        };
        cvGenotypeSymbol: {
            genotypeSymbol: string;
        };
    };
    id: number;
    job: {
        jobId: number;
    };
    jobId: number;
    lineId: number;
    line?: {
        lineName: string;
    };
    materialId: number;
    material?: {
        animal: {
            animalName: string;
        };
        sample: {
            sampleName: string;
        };
    };
    messageId: number;
    studyId: number;
    taskInstanceId: number;
    taskInstance?: {
        taskAlias: string;
    }
    workgroupId: number;
};

export type MessageMapDraft = {
    messageId: number;
    jobId: number;
} | {
    messageId: number;
    genotypeId: number;
} | {
    messageId: number;
    lineId: number;
} | {
    messageId: number;
    materialId: number;
} | {
    messageId: number;
    taskInstanceId: number;
};
