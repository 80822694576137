<div id="messagePanel">

    <div class="message-panel card">

        <div class="message-panel-header">
            <div class="message-panel-label-wrapper">
                <svg class="bell-icon" [climbIcon]="icons.bellFilled" size="sm"></svg>
                <span class="message-panel-label">Message Center</span>
            </div>
            <button climbButton size="md" variant="ghost" (click)="closeMessageCenter()">
                <svg [climbIcon]="icons.crossBig" size="sm"></svg>
            </button>
        </div>

        <div class="card-body">

            <!-- Toolbar -->
            <div class="row message-panel-toolbar">
                <div class="col-md-8 my-1">
                    <div class="input-group">
                        <input type="text"
                               [(ngModel)]="searchText"
                               placeholder="Search messages&hellip;"
                               class="form-control" />
                        <div class="input-group-append">
                            <button type="button"
                                    class="btn btn-secondary"
                                    (click)="clearSearch()"
                                    *ngIf="searchText">
                                <i class="fa fa-remove remove-item" title="Clear Search"></i>
                            </button>
                            <button type="button"
                                    class="btn btn-secondary"
                                    (click)="getMessages()">
                                <i class="fa fa-search" title="Search Messages"></i>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="col-md-4 text-right my-1">
                    <button type="button"
                            class="btn btn-primary"
                            [disabled]="showNewMessage"
                            (click)="showNewMessage = !showNewMessage">
                        <i class="fa fa-pencil" title="New Message"></i> New
                    </button>
                </div>
            </div>


            <!-- New Message -->
            <div class="new-message-container"
                 *ngIf="showNewMessage">
                <ngb-accordion [closeOthers]="true"
                               activeIds="new-message-panel">
                    <ngb-panel id="new-message-panel" title="New Message">
                        <ng-template ngbPanelContent>
                            <new-message [defaultValues]="defaultMessage"
                                         (save)="newMessageSaved()"
                                         (cancel)="newMessageCancelled()"></new-message>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
            </div>


            <!-- Mailbox tabs -->
            <nav ngbNav #nav="ngbNav" [destroyOnHide]="false" class="nav-tabs p-0">
                <ng-container ngbNavItem>
                    <a ngbNavLink>Inbox</a>
                    <ng-template ngbNavContent>
                        <ng-template [ngTemplateOutlet]="inBox"></ng-template>
                    </ng-template>
                </ng-container>

                <ng-container ngbNavItem>
                    <a ngbNavLink>Sent</a>
                    <ng-template ngbNavContent>
                        <ng-template [ngTemplateOutlet]="sentBox"></ng-template>
                    </ng-template>
                </ng-container>
            </nav>
            <div [ngbNavOutlet]="nav" class="mt-2"></div>

        </div>
    </div>
</div>


<ng-template #inBox>
    <div class="message-panel-toolbar text-right">

        <span *ngIf="loadingMessages" class="float-left">
            <i class="fa fa-2x fa-spinner fa-spin" aria-hidden="true"></i>
            Loading messages&hellip;
        </span>

        <button type="button"
                class="btn btn-secondary btn-fw-xwide"
                *ngIf="!showDismissed"
                (click)="showHideClick(true)">
            Show Read Messages
        </button>
        <button type="button"
                class="btn btn-warning btn-fw-xwide"
                *ngIf="showDismissed"
                (click)="showHideClick(false)">
            Hide Read Messages
        </button>
    </div>

    <div *ngIf="!loadingMessages && inBoxMessages?.length === 0">
        <i class="fa fa-check-circle text-success fa-2x" aria-hidden="true"></i>
        You have no unread messages.
    </div>

    <!-- Inbox Per Sender -->
    <ngb-accordion [closeOthers]="true">
        <ng-container *ngFor="let user of inBoxUserGroups">

            <ngb-panel>
                <ng-template ngbPanelTitle>
                    <span>From: {{user.FirstName}} {{user.LastName}}</span>
                    ({{user.messages?.length}})
                </ng-template>
                <ng-template ngbPanelContent>
                    <ng-container *ngFor="let message of user.messages |
                            paginate: { itemsPerPage: INBOX_MESSAGES_PER_PAGE,
                                currentPage: user.messagePage,
                                id: user.paginationId }">

                        <!-- Show/hide message based on dismissed status -->
                        <div class="message-detail"
                                [ngClass]="{'disabled-message': message.isDismissed}">
                            <div class="row">
                                <div class="col-md-12 message-metadata-wrapper">
                                    <div *ngIf="message.subject?.length > 0">
                                        <strong>{{message.subject}}</strong>
                                    </div>
                                    <div *ngIf="message.subject?.length === 0">
                                        (No Subject)
                                    </div>
                                    <div>
                                        {{message.dateGenerated | formatShortDateTime}}
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 message-text">
                                    {{message.messageText}}
                                </div>
                            </div>

                            <div *ngIf="message.messageMaps | notEmpty" class="row">
                                <div class="col-md-12">
                                    <b>Attached data:</b>
                                    <ng-container *ngFor="let mapping of message.messageMaps">
                                        <message-map-label [messageMap]="mapping"></message-map-label>
                                    </ng-container>
                                </div>
                            </div>

                            <!-- Replying -->
                            <ng-container *ngIf="message.sendingReply">
                                <div class="row">
                                    <div class="col-md-12">
                                        <textarea rows="3"
                                                    [(ngModel)]="message.ReplyText"
                                                    class="form-control"></textarea>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12 message-detail-btn-wrapper">
                                        <button type="button"
                                                class="btn btn-fw btn-primary"
                                                (click)="sendReply(message)">
                                            Reply
                                        </button>
                                        <button type="button"
                                                class="btn btn-fw btn-secondary"
                                                (click)="cancelReply(message)">
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </ng-container>

                            <!-- Not Replying -->
                            <div class="row" *ngIf="!message.sendingReply">
                                <div class="col-md-12 message-detail-btn-wrapper">
                                    <button type="button"
                                            class="btn btn-fw btn-primary"
                                            *ngIf="!message.isDismissed"
                                            (click)="message.sendingReply = true">
                                        Reply
                                    </button>
                                    <button type="button"
                                            class="btn btn-fw btn-secondary"
                                            *ngIf="!message.isDismissed"
                                            (click)="changeStatus(message, true)">
                                        Mark Read
                                    </button>
                                    <button type="button"
                                            class="btn btn-fw btn-secondary"
                                            *ngIf="message.isDismissed"
                                            (click)="changeStatus(message, false)">
                                        Mark Unread
                                    </button>
                                </div>

                            </div>
                        </div>
                    </ng-container>

                    <pagination-controls [id]="user.paginationId"
                             (pageChange)="user.messagePage = $event"
                             [autoHide]="true"
                             class="ngx-table-paginator"></pagination-controls>
                </ng-template>
            </ngb-panel> <!-- /user panel -->

        </ng-container>
    </ngb-accordion>
</ng-template>

<ng-template #sentBox>

    <span *ngIf="loadingMessages">
        <i class="fa fa-2x fa-spinner fa-spin" aria-hidden="true"></i>
        Loading sent messages&hellip;
    </span>

    <div *ngIf="!loadingMessages && sentMessages?.length === 0">
        You have no sent messages.
    </div>

    <table class="table"
           *ngIf="sentMessages | notEmpty">
        <thead>
            <tr>
                <th><a (click)="sortPredicate = 'DateGenerated'; reverse = !reverse">Date</a></th>
                <th><a (click)="sortPredicate = 'ToUser.LastName'; reverse = !reverse">To</a></th>
                <th><a (click)="sortPredicate = 'Subject'; reverse = !reverse">Subject</a></th>
                <th><a (click)="sortPredicate = 'MessageText'; reverse = !reverse">Message</a></th>
            </tr>
        </thead>
        <tfoot>
            <tr>
                <td colspan="4">
                    <pagination-controls [id]="sentPaginationId"
                        (pageChange)="sentPage = $event"
                        [autoHide]="true"
                        class="ngx-table-paginator"></pagination-controls>
                </td>
            </tr>
        </tfoot>
        <tbody>
            <ng-container *ngFor="let message of sentMessages | orderBy:sortPredicate:reverse |
                        paginate: { itemsPerPage: SENT_MESSAGES_PER_PAGE,
                                    currentPage: sentPage,
                                    id: sentPaginationId }">
                <tr [ngClass]="{'disabled-message': message.isDismissed}">
                    <td>
                        {{message.dateGenerated | formatShortDateTime}}
                    </td>
                    <td>
                        {{message.toUser.firstName}}
                        {{message.toUser.lastName}}
                    </td>
                    <td>
                        <div *ngIf="message.subject?.length > 0">
                            {{message.subject}}
                        </div>
                        <div *ngIf="message.subject?.length === 0">
                            <i>(No Subject)</i>
                        </div>
                    </td>
                    <td>
                        {{message.messageText | slice:0:25}}
                        <a *ngIf="!message.textExpanded && message.messageText?.length > 25"
                           (click)="showHideMessageContent(message)">&hellip;</a>
                        <a *ngIf="message.textExpanded"
                           (click)="showHideMessageContent(message)">[Hide]</a>
                    </td>
                </tr>
                <tr [ngClass]="{'disabled-message': message.isDismissed}">
                    <td colspan="4"
                        *ngIf="message.textExpanded">
                        {{message.messageText}}
                    </td>
                </tr>
            </ng-container>
        </tbody>
    </table>
</ng-template>
