import {
    Component,
    Input
} from '@angular/core';
import { MessageMap } from './models';

/**
 * Display a messageMap label
 */
@Component({
    selector: 'message-map-label',
    template: `
        <span class="label label-primary">
            <span *ngIf="messageMap.job">
                {{messageMap.job.jobId}}
            </span>
            <span *ngIf="messageMap.material && messageMap.material.animal">
                {{messageMap.material.animal.animalName}}
            </span>
            <span *ngIf="messageMap.material && messageMap.material.sample">
                {{messageMap.material.sample.sampleName}}
            </span>
            <span *ngIf="messageMap.genotype">
                {{messageMap.genotype.cvGenotypeAssay.genotypeAssay}}
                {{messageMap.genotype.cvGenotypeSymbol.genotypeSymbol}}
            </span>
            <span *ngIf="messageMap.line">
                {{messageMap.line.lineName}}
            </span>
            <span *ngIf="messageMap.taskInstance">
                {{messageMap.taskInstance.taskAlias}}
            </span>
        </span>
    `
})
export class MessageMapLabelComponent {
    @Input() messageMap: MessageMap;
}
