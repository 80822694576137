import { Inject, Injectable, NgZone } from '@angular/core';
import { EVENTS_TOKEN, Events } from '../mf-store';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModernAppService {
  private readonly APP_NAME = 'climbWeb';
  private container: {
    init(value: unknown): Promise<void>
    get<T = unknown>(value: string): Promise<() => T>
  }

  private initStatus$$ = new BehaviorSubject<'unknown' | 'success' | 'error'>('unknown');

  initStatus$ = this.initStatus$$.asObservable();

  constructor(
    private ngZone: NgZone,
    @Inject(EVENTS_TOKEN)
    private events: Events,
  ) {}

  init(): void {
    this.ngZone.runOutsideAngular(() => {
      this.loadApp()
        .then(() => this.initApp())
        .then(() => this.loadModule<{ bootstrap: () => void }>('./bootstrap'))
        .then(({ bootstrap }) => bootstrap())
        .then(() => this.initStatus$$.next('success'))
        .catch(console.error)
        .then(() => this.initStatus$$.next('error'));
    });
  }

  openComponent(elementId: string, componentId: string, facetId?: string,): void {
    this.ngZone.runOutsideAngular(() => {
      this.events.openComponent.emit({ elementId, componentId, facetId });
    });
  }

  private loadApp() {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      const newFacetsUrl = window['newFacetsUrl'];
      if (!newFacetsUrl) {
        reject(new Error('newFacetsUrl is not provided'));
        return;
      }
      script.src = `${newFacetsUrl}remoteEntry.js`;
      script.onload = () => {
        resolve({
          get: (request: unknown) => window[this.APP_NAME].get(request),
          init: (arg: unknown) => {
            try {
              return window[this.APP_NAME].init(arg);
            } catch (e) {
              console.log("climbWeb has already been loaded");
            }
          },
        })
      }

      script.onerror = () => {
        reject(new Error(`Error loading of ${newFacetsUrl}`));
      };

      document.body.append(script);
    });
  }

  private async initApp() {
    await __webpack_init_sharing__("default");
    this.container = window[this.APP_NAME]; // or get the container somewhere else
    // Initialize the container, it may provide shared modules
    await this.container.init(__webpack_share_scopes__.default);
  }

  private async loadModule<T = unknown>(moduleName: string) {
    const module = await this.container.get<T>(moduleName);
    return module();
  }
}
